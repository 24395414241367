import React, { useState, useContext, createContext, useMemo } from 'react';
import { generateRandomNumber } from '../../helpers/functions';
const AlertContext = createContext([]);

export const Alert = ({ children }) => {
  const [alerts, setAlert] = useState([]);

  const clearAlerts = () => setAlert([]);

  const clearAlert = (id) => {
    if (alerts && alerts.length > 0) {
      const remainingAlerts = alerts.filter((alert) => alert.id !== id);
      setAlert(remainingAlerts);
    }
  };

  const showAlert = (payload) => {
    const id = generateRandomNumber();
    const alertPayload = {
      id,
      message: payload.message,
      type: payload.type,
    };

    setAlert((prev) => [...prev, alertPayload]);
    setTimeout(() => {
      setAlert((prev) => prev.filter((item) => item.id !== alertPayload.id));
    }, 5000);
  };

  const values = useMemo(
    () => ({
      alerts,
      setAlert,
      clearAlerts,
      clearAlert,
      showAlert,
    }),
    [alerts],
  );
  return (
    <AlertContext.Provider value={values}>{children}</AlertContext.Provider>
  );
};

export const useAlert = () => useContext(AlertContext);
