import React, { useState } from 'react';
import AsideModal from '../../../../components/AsideModal';
import TableManager from '../../../../components/TableManager';
import { getAgentAgenciesMembers } from '../../../../api';

export default function AgencyMemberList({ agency_uuid, children }) {
  const [showAgencyMembers, setShowAgencyMembers] = useState(false);

  const handleClose = () => {
    setShowAgencyMembers(false);
  };

  const columns = [
    {
      accessorKey: 'first_name',
      header: () => 'Name',
      cell: (props) => {
        return (
          <span className="d--flex gap--sm  align-items--center">
            {props.getValue()}
          </span>
        );
      },
    },
    {
      accessorKey: 'email',
      header: () => 'Email',
    },
    {
      accessorKey: 'extension',
      header: () => 'Extension',
      meta: {
        textAlign: 'center',
        width: '3%',
      },
    },
  ];

  return (
    <span>
      <span onClick={() => setShowAgencyMembers(true)}>{children}</span>
      {showAgencyMembers ? (
        <AsideModal
          handleClose={handleClose}
          title={`Agency Members`}
          footerComponent={null}
          width="sm"
        >
          <div className="p-l--sm p-r--sm">
            <TableManager
              {...{
                showHeader: false,
                fetcherKey: 'getAgentAgenciesMembers',
                fetcherFn: getAgentAgenciesMembers,
                columns,
                name: 'Employees',
                showPagination: true,
                extraParams: {
                  agency_uuid,
                },
              }}
            />
          </div>
        </AsideModal>
      ) : null}
    </span>
  );
}
