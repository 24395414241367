import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { codecsOption, intialValues, validationSchema } from '../constants';
import { yupResolver } from '@hookform/resolvers/yup';
import MultiDropDown from '../../../components/MultiDropdown';
import FormInput from '../../../components/FormInput';
import useProviderAdd from '../../../hooks/useProviderAdd';
import useProviderUpdate from '../../../hooks/useProviderUpdate';
import Button from '../../../components/Button';
import FormSelect from '../../../components/FormSelect';

const renderRateCardItem = ({ item }) => {
  return (
    <option key={item?.uuid} value={item?.uuid}>
      {item?.name}
    </option>
  );
};

const AddProvider = ({ setOpenSlider, rowData, rateCardData = [] }) => {
  const { uuid, ...rest } = rowData || {};
  const { mutateAsync: providerUpdateMutate, isLoading: provideUpdateLoad } =
    useProviderUpdate();
  const { mutateAsync: providerAddMutate, isLoading: provideAddLoad } =
    useProviderAdd();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: intialValues,
    mode: 'onChange',
  });

  useEffect(() => {
    if (!uuid) return;
    const {
      name,
      add_prefix,
      remove_prefix,
      host_ip_outbound,
      host_ip_inbound,
      allow_codecs,
      ratecard_uuid,
    } = rest;
    reset({
      name,
      add_prefix,
      remove_prefix,
      host_ip_outbound,
      host_ip_inbound,
      allow_codecs: allow_codecs.split(','),
      rate_card_uuid: ratecard_uuid,
    });
  }, [uuid]);
  const handleSubmitForm = async (data) => {
    const {
      name,
      add_prefix,
      remove_prefix,
      host_ip_outbound,
      host_ip_inbound,
      allow_codecs = [],
      rate_card_uuid,
    } = data;
    const payload = {
      name,
      add_prefix,
      remove_prefix,
      host_ip_outbound,
      host_ip_inbound,
      allow_codecs: allow_codecs?.join(','),
      ratecard_uuid: rate_card_uuid,
      uuid: uuid || undefined,
    };
    if (uuid) {
      await providerUpdateMutate(payload);
    } else {
      await providerAddMutate(payload);
    }

    setOpenSlider(false);
    reset();
  };
  const isLoading = provideUpdateLoad || provideAddLoad;
  return (
    <form
      className="w--full d--flex flex--column gap--md"
      onSubmit={handleSubmit(handleSubmitForm)}
      autoComplete="off"
    >
      <div className="overflow--auto dialogScroll w--full d--flex flex--column gap--md p--md">
        <div>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="input"
                label="Provider Name"
                placeholder="Enter provider name"
                error={errors?.name?.message}
                maxLength={25}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="add_prefix"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="input"
                label="Add Prefix"
                placeholder="Enter Add Prefix"
                error={errors.add_prefix?.message}
                maxLength={30}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="remove_prefix"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="input"
                label="Remove Prefix"
                placeholder="Enter Remove Prefix"
                error={errors.remove_prefix?.message}
                maxLength={30}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="host_ip_inbound"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="input"
                label="Inbound IP Host"
                placeholder="Enter Inbound IP Host"
                error={errors.host_ip_inbound?.message}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="host_ip_outbound"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="input"
                label="Outbound IP Host"
                placeholder="Enter Outbound IP Host"
                error={errors.host_ip_inbound?.message}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="allow_codecs"
            control={control}
            render={({ field }) => (
              <MultiDropDown
                {...field}
                labelKey={'label'}
                valueKey={'value'}
                label="Allow Codecs"
                optionList={codecsOption}
                error={errors?.allow_codecs?.message}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="rate_card_uuid"
            control={control}
            render={({ field }) => (
              <FormSelect
                {...field}
                options={rateCardData || []}
                renderOption={renderRateCardItem}
                placeholder="Select"
                label="Rate Card"
                error={errors.rate_card_uuid?.message}
              />
            )}
          />
        </div>
      </div>

      <div className="d--flex justify-content--between gap--md p-r--md p-l--md">
        <Button
          type="button"
          variant="contrast"
          color="primary"
          btnClasses="btn"
          onClick={() => setOpenSlider(false)}
        >
          Cancel
        </Button>

        <Button
          type="submit"
          size=""
          variant="primary"
          color="white"
          btnClasses="btn"
          disabled={isLoading}
        >
          {isLoading ? 'Please wait...' : uuid ? 'Update' : 'Save'}
        </Button>
      </div>
    </form>
  );
};

export default AddProvider;
