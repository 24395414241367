import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import useClickOutside from '../../hooks/useClickOutside';
import FormInput from '../FormInput';

const Dropdown = ({
  children,
  dropList = {},
  closeOnClickOutside = false,
  caretComponent: CaretComponent = null,
  showcaret = false,
  withSearch = false,
  manualCloseOnClick = false,
  header: Header = null,
  footer: Footer = null,
  search = '',
  setSearch = () => {},
  extraClasses = '',
}) => {
  const dropdownRef = useRef(null);
  const [show, setShow] = useState(false);
  const [shouldShowStyle, setShouldSetStyle] = useState(false);
  const elRef = useRef(null);
  useClickOutside([elRef], () => {
    setShow(false);
  });
  // useLayoutEffect(() => {
  //   let element = document.getElementById('dropdown-menu-elm');
  //   if (element) {
  //     if (
  //       window.innerHeight - element.getBoundingClientRect().bottom >=
  //       element.scrollHeight
  //     ) {
  //       setShouldSetStyle(false);
  //     } else {
  //       setShouldSetStyle(true);
  //     }
  //   }
  // }, [show]);

  useEffect(() => {
    // Calculate dropdown position on mount and window resize
    calculateDropdownPosition();
    window.addEventListener('resize', calculateDropdownPosition);
    return () => {
      window.removeEventListener('resize', calculateDropdownPosition);
    };
  }, [show]);

  const calculateDropdownPosition = () => {
    if (dropdownRef.current) {
      const dropdownHeight = dropdownRef.current.offsetHeight;
      const windowHeight = window.innerHeight;
      setShouldSetStyle((prev) => {
        let dropdownTop;
        let spaceBelow;
        if (!prev) {
          dropdownTop = dropdownRef.current.getBoundingClientRect().top;
          spaceBelow = windowHeight - dropdownTop;
        } else {
          dropdownTop = dropdownRef.current.getBoundingClientRect().bottom;
          spaceBelow = windowHeight - dropdownTop;
        }
        return spaceBelow < dropdownHeight;
      });
    }
  };

  return (
    <dropdown-element
      dropdown-element
      ref={elRef}
      onClick={(e) => {
        e.preventDefault();
        if (closeOnClickOutside) {
          setShow((prev) => !prev);
        } else {
          setShow(true);
        }
      }}
    >
      {children}

      {showcaret && (
        <dropdown-caret dropdown-caret>
          <CaretComponent />
        </dropdown-caret>
      )}

      {show && (
        <dropdown-menu
          ref={dropdownRef}
          id="dropdown-menu-elm"
          dropdown-menu
          class={`${extraClasses} ${
            shouldShowStyle ? 'bottom--100' : 'top--100'
          }`}
        >
          {Header ? (
            <dropdown-header>
              <Header />
            </dropdown-header>
          ) : null}
          <dropdown-menu-body dropdown-menu-body>
            {withSearch ? (
              <div>
                <FormInput
                  type="text"
                  extraClasses={`w--full`}
                  label={''}
                  placeholder={'Search'}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            ) : null}

            {dropList?.data?.length > 0 ? (
              dropList.data.map((item, index) => {
                const { access = true } = item;
                if (!access)
                  return <React.Fragment key={index}></React.Fragment>;
                return (
                  <dropdown-menu-item
                    dropdown-menu-item
                    key={item.id}
                    disabled={dropList?.isDisabled}
                  >
                    <dropList.component item={item} setShow={setShow} />
                  </dropdown-menu-item>
                );
              })
            ) : (
              <div className="d--flex align-items--center justify-content--center h--full w--full">
                No Record Found
              </div>
            )}
          </dropdown-menu-body>
          {Footer ? (
            <dropdown-footer>
              <Footer />
            </dropdown-footer>
          ) : null}
        </dropdown-menu>
      )}
    </dropdown-element>
  );
};

export default Dropdown;
