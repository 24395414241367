import React from 'react';
import { router } from './router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider } from 'react-router-dom';
import { Auth } from './hooks/useAuth';
import { Alert } from './hooks/useAlert';
import moment from 'moment';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

moment.updateLocale('en', {
  week: {
    dow: 1,
  },
});

export default function App() {
  return (
    <Alert>
      <Auth>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      </Auth>
    </Alert>
  );
}
