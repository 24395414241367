import { useState } from 'react';
import Member from './Member';
import Agents from './Agents';

export default function EmployeesAgents() {
  const [tab, setTab] = useState(0);

  const handleTab = (tab) => {
    switch (tab) {
      case 0:
        return <Member tab={tab} setTab={setTab} />;
      case 1:
        return <Agents tab={tab} setTab={setTab} />;

      default:
        break;
    }
  };
  return <>{handleTab(tab)}</>;
}
