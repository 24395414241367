import { useMutation } from '@tanstack/react-query';
import { rateCardDropdown } from '../../api';
import { useAlert } from '../useAlert';

export default function useRateCardDropdown() {
  const { showAlert } = useAlert();
  const Request = useMutation({
    mutationFn: rateCardDropdown,
    mutationKey: 'rateCardDropdown',
    onSuccess: ({ data }) => {
      // showAlert({
      //   type: 'success',
      //   message: data?.message ?? 'Success',
      // });
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...Request };
}
