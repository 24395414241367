import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addUser } from '../../api';
import { useAlert } from '../useAlert';

export default function useAddUser(handleClose) {
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();
  const addUserRequest = useMutation({
    mutationFn: addUser,
    mutationKey: 'addUser',
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries('getUsers');
      showAlert({
        type: 'success',
        message: data?.message ?? 'User added sucessfully',
      });
      handleClose();
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...addUserRequest };
}
