import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { getAgentProfileDetails } from '../../../../api';
import { useParams } from 'react-router-dom';
import { Country } from 'country-state-city';
import { formatDate } from '../../../../helpers/functions';
import Avatar from '../../../../components/Avatar';
import EditInfo from './EditInfo';
import PlanDetails from './PlanDetails';

const Profile = () => {
  const { id = '' } = useParams();
  const { data } = useQuery({
    queryKey: ['get-agent-profile-details'],
    queryFn: () => getAgentProfileDetails({ uuid: id }),
    select: (data) => data?.data?.data,
  });
  const {
    created_at = '',
    first_name = '',
    last_name = '',
    account_number = '',
    email = '',
    country = '',
    phone = '',
    plan = {},
    plan_start_date = '',
    amount = '',
    plan_expiration_date = '',
    did_count = '',
    member_count = '',
    profile_pic = '',
    uuid = '',
    parent_uuid = '',
  } = data || {};
  return (
    <div className="d--flex w--full flex--column profilePage">
      <h4 className="font--md font--600 m-b--md h-max--40 m-t--sm">Profile</h4>
      <div className="d--flex gap--md flex--column profilePageSec bg--white p--md radius--sm h--full overflow--auto box-shadow--xs">
        <div className="w--full d--flex flex--column gap--md">
          <div className="label--control font--sm font--500 m-b--xs  h-min--36  d--flex gap--sm align-items--center text--primary bg--primary-100 border-full--primary-100 p-t--sm p-b--sm p-r--md p-l--md radius--sm ">
            Personal Info<div class="d--flex"></div>
          </div>
          <div className="d--flex gap--xl">
            <div className="w--full w-max--200 h-min--200 bg--primary-100 radius--sm d--flex align-items--center justify-content--center">
              <Avatar
                image={
                  data?.profile_pic
                    ? `${process.env.REACT_APP_MEDIA_URL}${
                        parent_uuid || parent_uuid || uuid
                      }/profile?filename=${profile_pic}`
                    : null
                }
                name={first_name + last_name}
                size="100"
              />
              {/* <UserIcon width={80} height={80} /> */}
            </div>
            <div className="d--flex flex--column gap--md w--full">
              <div className="w--full d--flex gap--xl">
                <div className="d--flex flex--column gap--xs w--full">
                  <div className="font--sm font--600">Registration Date</div>
                  <div className="font--sm ">{formatDate(created_at)}</div>
                </div>
              </div>
              <div className="d--flex flex--column gap--xs w--full">
                <div className="font--sm font--600">Phone</div>
                <div className="font--sm ">{phone}</div>
              </div>
              {/* <div className="d--flex flex--column gap--xs w--full">
                <div className="font--sm font--600">Plan name:</div>
                <div className="font--sm ">{plan?.plan_name}</div>
              </div> */}
              <div className="d--flex flex--column gap--xs w--full">
                <div className="font--sm font--600">No. of DID:</div>
                <div className="font--sm ">{did_count}</div>
              </div>
            </div>
            <div className="d--flex flex--column gap--md w--full">
              <div className="w--full d--flex gap--xl">
                <div className="d--flex flex--column gap--xs w--full">
                  <div className="font--sm font--600">Full Name</div>
                  <div className="font--sm ">
                    {first_name} {last_name}
                  </div>
                </div>
              </div>
              <div className="d--flex flex--column gap--xs w--full">
                <div className="font--sm font--600">Account Number</div>
                <div className="font--sm ">{account_number}</div>
              </div>
              {/* <div className="d--flex flex--column gap--xs w--full">
                <div className="font--sm font--600">Plan Start date:</div>
                <div className="font--sm ">{plan_start_date}</div>
              </div> */}
              <div className="d--flex flex--column gap--xs w--full">
                <div className="font--sm font--600">No. of Members:</div>
                <div className="font--sm ">{member_count}</div>
              </div>
            </div>
            <div className="d--flex flex--column gap--md w--full">
              <div className="w--full d--flex gap--xl">
                <div className="d--flex flex--column gap--xs w--full">
                  <div className="font--sm font--600">Email</div>
                  <div className="font--sm ">{email}</div>
                </div>
              </div>
              <div className="d--flex flex--column gap--xs w--full">
                <div className="font--sm font--600">Country:</div>
                <div className="font--sm ">
                  {Country.getCountryByCode(country)?.name}
                </div>
              </div>
              {/* <div className="d--flex flex--column gap--xs w--full">
                <div className="font--sm font--600">Plan Renewal date:</div>
                <div className="font--sm ">{plan_expiration_date}</div>
              </div> */}
              <div className="d--flex flex--column gap--xs w--full">
                <div className="font--sm font--600">Balance:</div>
                <div className="font--sm ">$ {amount}</div>
              </div>
            </div>
          </div>
        </div>
        {/* <EditInfo user={data} /> */}
        <PlanDetails />
      </div>
    </div>
  );
};

export default Profile;
