import React, { useState } from 'react';
import useIcons from '../../../assets/icons/useIcons';
import { callRatesListing } from '../../../api';
import TableManager from '../../../components/TableManager';
import Dropdown from '../../../components/Dropdown';
import Button from '../../../components/Button';
import AsideModal from '../../../components/AsideModal';
import UpdateCallRates from './UpdateCallRates';

const CallRateDetails = ({ rowData }) => {
  const [openSlider, setOpenSlider] = useState(false);
  const { MoreVIcon, TrashIcon, PencilIcon } = useIcons();
  const handleEditData = () => {};
  const handleDeactivateBtn = () => {};
  const handleActivateBtn = () => {};
  const tableActions = [
    // {
    //   id: 1,
    //   component: (
    //     <Button
    //       type="button"
    //       btnClasses="btn w-min--150"
    //       variant="primary"
    //       color="white"
    //       onClick={() => {
    //         setOpenSlider(true);
    //       }}
    //     >
    //       <PencilIcon />
    //       Update Call Rates
    //     </Button>
    //   ),
    // },
  ];
  console.log('iopoejjnmdsl');
  const handleDropdownClick = ({ value = '' }, data) => {
    switch (value) {
      case 'edit':
        handleEditData(data);
        break;
      case 'deactivate':
        handleDeactivateBtn(data);
        break;
      case 'activate':
        handleActivateBtn(data);
        break;
      default:
        break;
    }
  };

  const columns = [
    {
      accessorKey: 'destination',
      header: () => 'Destination',
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'dialprefix',
      header: () => 'Dial Prefix',
    },
    {
      accessorKey: 'rate',
      header: () => 'Rate',
    },
    {
      accessorKey: 'billingblock',
      header: () => 'Billing Block',
    },
    {
      accessorKey: 'status',
      header: () => 'Status',
      cell: (props) => {
        if (props?.getValue() === 'A') {
          return 'Activated';
        } else {
          return 'Deactivated';
        }
      },
    },
    // {
    //   accessorKey: 'action',
    //   header: () => 'Action',
    //   cell: (props) => {
    //     const element = props?.row?.original || {};
    //     const dropDownOptions = [{ title: 'Edit', value: 'edit' }];
    //     if (element?.status === 'A') {
    //       dropDownOptions.push({ title: 'Deactivate', value: 'deactivate' });
    //     } else {
    //       dropDownOptions.push({ title: 'Activate', value: 'activate' });
    //     }
    //     return (
    //       <span>
    //         <Dropdown
    //           closeOnClickOutside={true}
    //           showcaret={false}
    //           dropList={{
    //             component: ({ item }) => (
    //               <div
    //                 className="w--full d--flex  justify-content--start"
    //                 onClick={() => {
    //                   handleDropdownClick(item, element);
    //                 }}
    //               >
    //                 {item?.title === 'Edit' && <PencilIcon />}
    //                 {item?.title}
    //               </div>
    //             ),
    //             data: dropDownOptions,
    //           }}
    //         >
    //           <MoreVIcon />
    //         </Dropdown>
    //       </span>
    //     );
    //   },
    // },
  ];
  return (
    <>
      <TableManager
        fetcherKey="callRatesListing"
        fetcherFn={callRatesListing}
        columns={columns}
        tableActions={tableActions}
        extraParams={{
          uuid: rowData?.uuid,
        }}
        showHeader={true}
        showPagination={true}
      />
      {openSlider && (
        <AsideModal
          handleClose={() => {
            setOpenSlider(false);
          }}
          title={'Update Call Rate'}
          footerComponent={null}
        >
          <UpdateCallRates {...{ setOpenSlider }} />
        </AsideModal>
      )}
    </>
  );
};

export default CallRateDetails;
