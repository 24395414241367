import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteEmployee } from '../../api';
import { useAlert } from '../useAlert';

export default function useDeleteUserEmployee() {
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();
  const memberDeleteRequest = useMutation({
    mutationFn: deleteEmployee,
    mutationKey: 'deleteEmployee',
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries('getAgentMembers');
      showAlert({
        type: 'success',
        message: data?.message ?? 'Member deleted sucessfully',
      });
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...memberDeleteRequest };
}
