import React, { Fragment, useEffect, useState } from 'react';
import TableManager from '../../components/TableManager';
import useGetAllUsers from '../../hooks/useGetAllUsers';
import { getSMS, getUserAllMemberAgentDropdown } from '../../api';
import {
  converDateTimeFormateApis,
  formatPhoneNumber,
} from '../../helpers/functions';
import { useQuery } from '@tanstack/react-query';

const RenderSearchItem = ({ item }) => {
  return (
    <option key={item.uuid} value={item?.uuid}>
      {item?.name}
    </option>
  );
};

const SMS = () => {
  const [selectedUserId, setSelectedUserId] = useState('');
  const { mutate: allUsersMutate, data: allUsersData } = useGetAllUsers();
  useEffect(() => {
    allUsersMutate();
  }, []);

  const { data: userMemberAgentListing = [] } = useQuery({
    queryKey: ['getUserAllMemberAgentDropdown', selectedUserId],
    queryFn: () => getUserAllMemberAgentDropdown({ uuid: selectedUserId }),
    select: (data) => data?.data?.data,
    enabled: Boolean(selectedUserId),
    cacheTime: Infinity,
    keepPreviousData: true,
    refetchOnMount: false,
    staleTime: true,
  });

  const filterInitialValues = {
    filter: [
      {
        key: 'from',
        value: '',
        isChecked: '',
        inputPlaceholder: 'Enter from',
        inputType: 'text',
        inputLabel: 'From',
      },
      {
        key: 'to',
        value: '',
        isChecked: '',
        inputPlaceholder: 'Enter to',
        inputType: 'text',
        inputLabel: 'To',
      },
      {
        key: 'extension',
        value: '',
        isChecked: false,
        inputPlaceholder: 'Enter Extension Number',
        inputType: 'multi-select',
        inputLabel: 'Extension',
        inputOptions: userMemberAgentListing.map((item) => {
          return {
            label: item?.name,
            name: item?.name,
            value: item?.extension,
            profile_pic: item?.profile_pic,
            extension: item?.extension,
          };
        }),
      },
    ],
  };
  const columns = [
    {
      accessorKey: 'created_at',
      header: () => 'Date/Time',
      cell: (props) => {
        return <>{converDateTimeFormateApis(props?.getValue())}</>;
      },
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'user.account_number',
      header: () => 'Account No',
    },
    {
      accessorKey: 'from',
      header: () => 'From',
      cell: (props) => {
        return formatPhoneNumber(props?.getValue());
      },
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'via_did',
      header: () => 'Via Virtual Number',
      cell: (props) => {
        const element = props?.row?.original;
        return (
          <>
            <div className="d--flex flex--column justify-content--start align-items--center">
              {element?.did_name && <div>{element?.did_name}</div>}
              <div>{formatPhoneNumber(props?.getValue())}&nbsp;</div>
            </div>
          </>
        );
      },
      meta: {
        width: '12%',
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'to',
      header: () => 'To',
      cell: (props) => {
        return (
          <span className="d--flex flex--column align-items--center justify-content--start gap--sm">
            {/* <div>{getNumberName(props?.getValue())}</div> */}
            {/* <div className="font--xs"> */}
            {/* {getNumberName(props?.getValue())?.includes('+')
                ? ''
                : */}
            {formatPhoneNumber(props?.getValue())}
            {/* </div> */}
          </span>
        );
      },
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'direction',
      header: () => 'Direction',
      cell: (props) => {
        return (
          <span
            style={{ textTransform: 'capitalize' }}
            className={
              props?.getValue() === 'Sent' ? 'text--success' : 'text--info'
            }
          >
            {props?.getValue()}
          </span>
        );
      },
      meta: {
        width: '5%',
      },
    },
    // {
    //   accessorKey: 'charge',
    //   header: () => 'Charge($)',
    //   meta: {
    //     textAlign: 'right',
    //   },
    // },
  ];
  return (
    <Fragment>
      <div className="px-3">
        <TableManager
          {...{
            name: 'SMS',
            fetcherKey: 'getSMS',
            fetcherFn: getSMS,
            columns,
            isSearching: true,
            searchList: allUsersData?.data?.data || [],
            SearchComponentOption: RenderSearchItem,
            searchKey: 'uuid',
            shouldFilter: true,
            initialFilterState: filterInitialValues,
            shouldShowTotalCount: true,
            customFilters: true,
            enableDefaultFilter: 'Show All',
            onChangeSearchPayload: (uuid) => {
              setSelectedUserId(uuid);
            },
          }}
        />
      </div>
    </Fragment>
  );
};

export default SMS;
