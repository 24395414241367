import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAlert } from '../useAlert';
import { deleteProvider } from '../../api';

export default function useProviderDelete() {
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();
  const Request = useMutation({
    mutationFn: deleteProvider,
    mutationKey: 'deleteProvider',
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries('providerListing');
      showAlert({
        type: 'success',
        message: data?.message ?? 'Carrier deleted successfully',
      });
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...Request };
}
