import React from 'react';
import TableManager from '../../components/TableManager';
import { transactionListing } from '../../api';
import { DateFilterTypes, formatDate } from '../../helpers/functions';
import useIcons from '../../assets/icons/useIcons';
import Dropdown from '../../components/Dropdown';

const Transaction = () => {
  const { MoreVIcon } = useIcons();
  const handlePay = () => {};
  const handleRefund = () => {};

  const handleDropdownClick = ({ value = '' }, data) => {
    switch (value) {
      case 'pay':
        handlePay(data);
        break;
      case 'refund':
        handleRefund(data);
        break;
      default:
        break;
    }
  };

  const filterInitialValues = {
    filter: [
      {
        key: 'account_number',
        value: '',
        isChecked: false,
        inputPlaceholder: 'Enter account number',
        inputType: 'number',
        inputLabel: 'Account number',
      },

      {
        key: 'billno',
        value: '',
        isChecked: '',
        inputPlaceholder: 'Enter transaction number',
        inputType: 'text',
        inputLabel: 'Transaction no',
      },
      {
        key: 'type',
        value: '',
        isChecked: '',
        inputPlaceholder: 'Enter type',
        inputType: 'select',
        inputLabel: 'Type',
        inputOptions: [],
      },

      // {
      //   key: 'date',
      //   value: {
      //     form: '',
      //     to: '',
      //   },
      //   inputType: 'date',
      //   dateType: '',
      //   dateFilterTypes: DateFilterTypes,
      // },
    ],
  };

  const columns = [
    {
      accessorKey: 'created_at',
      header: () => 'Date',
      cell: (props) => formatDate(props.getValue()),
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'billno',
      header: () => 'Transaction No',
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'user_uuid',
      header: () => 'Account No',
      meta: {
        textAlign: 'center',
      },
      cell: (props) => {
        const {
          first_name = '',
          last_name = '',
          email = '',
        } = props?.row?.original?.user || {};
        return (
          <span
            data-tooltip={`
                ${first_name} ${last_name}
                ${email}
              `}
            tooltip-position="top"
            className="c--pointer text--danger font--600 d--flex gap--sm align-items--center justify-content--center"
          >
            {props?.row?.original?.user?.account_number}
          </span>
        );
      },
    },
    {
      accessorKey: 'type',
      header: () => 'Type',
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'mode',
      header: () => 'Mode',
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'desc',
      header: () => 'Description',
    },
    {
      accessorKey: 'total_amount',
      header: () => 'Amount',
      cell: (props) => {
        return <b>${props.getValue()}</b>;
      },
      meta: {
        textAlign: 'right',
      },
    },
    {
      accessorKey: 'status',
      header: () => 'Status',
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'action',
      header: () => 'Action',
      cell: (props) => {
        const element = props?.row?.original || {};
        const dropDownOptions = [
          { title: 'Pay', value: 'pay' },
          { title: 'Refund', value: 'refund' },
        ];
        return (
          <span>
            <Dropdown
              closeOnClickOutside={true}
              showcaret={false}
              dropList={{
                component: ({ item }) => (
                  <div
                    className="w--full d--flex  justify-content--start"
                    onClick={() => {
                      handleDropdownClick(item, element);
                    }}
                  >
                    {item?.title === 'Pay' && 'Icon'}
                    {item?.title === 'Refund' && 'Icon'}
                    {item?.title}
                  </div>
                ),
                data: dropDownOptions,
              }}
              extraClasses="w-min--150"
            >
              <div className="w-min--32 h-min--32 d--flex align-items--center justify-content--center radius--sm bg--black-100 text--black  c--pointer">
                <MoreVIcon width={15} height={15} />
              </div>
            </Dropdown>
          </span>
        );
      },
      meta: {
        width: '5%',
      },
    },
  ];

  return (
    <div className="px-3">
      <TableManager
        {...{
          name: 'Transaction',
          columns,
          fetcherFn: transactionListing,
          fetcherKey: 'transaction-listing',
          shouldFilter: true,
          initialFilterState: filterInitialValues,
          shouldShowTotalCount: true,
          customFilters: true,
        }}
      />
    </div>
  );
};

export default Transaction;
