import React, { Fragment, useState } from 'react';
import TableManager from '../../components/TableManager';
import { getPlans } from '../../api';
import Button from '../../components/Button';
import AsideModal from '../../components/AsideModal';
import AddPlan from './AddPlan';
import Dropdown from '../../components/Dropdown';
import useIcons from '../../assets/icons/useIcons';
import useActivatePlan from '../../hooks/useActivatePlan';
import useDeActivatePlan from '../../hooks/useDeactivatePlan';

const Plans = () => {
  const { MoreVIcon } = useIcons();
  const [openSlider, setOpenSlider] = useState(false);
  const [rowData, setRowData] = useState({});
  const [isView, setIsView] = useState(false);
  const { mutate: activatePlanMutate } = useActivatePlan();
  const { mutate: deactivatePlanMutate } = useDeActivatePlan();
  const handleDropListClick = ({ value }, data) => {
    switch (value) {
      case 'edit':
        setRowData(data);
        setOpenSlider(true);
        break;
      case 'view':
        setRowData(data);
        setOpenSlider(true);
        setIsView(true);
        break;
      case 'activate':
        activatePlanMutate({ uuid: data?.uuid });
        break;
      case 'deactivate':
        deactivatePlanMutate({ uuid: data?.uuid });
        break;

      default:
        break;
    }
  };

  const tableActions = [
    {
      id: 1,
      access: true,
      component: (
        <Button
          type="button"
          btnClasses="btn w-min--150"
          variant="primary"
          color="white"
          onClick={() => {
            setOpenSlider(true);
            setRowData({});
          }}
        >
          Add Plan
        </Button>
      ),
    },
  ];

  const columns = [
    {
      accessorKey: 'plan_name',
      header: () => 'Plan Name',
      meta: {
        textAlign: 'left',
      },
    },

    {
      accessorKey: 'monthly_cost',
      header: () => 'Monthly Price($)',
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'yearly_cost',
      header: () => 'Yearly Price($)',
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'number_of_member',
      header: () => 'Agents',
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'per_member_addon_amt',
      header: () => 'Per Agent Cost($)',
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'number_of_dids',
      header: () => 'Number of DIDs',
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'did_cost',
      header: () => 'DID Cost ($)',
      cell: (props) => <span>{`${props?.getValue()}/mo`}</span>,
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'free_calls',
      header: () => 'Free Minutes',
      cell: (props) => <span>{`${props?.getValue()} (call mins)`}</span>,
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'free_sms',
      header: () => 'Free SMS',
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'status',
      header: () => 'Status',
      cell: (props) => {
        if (props?.getValue() === 'A') {
          return (
            <div
              data-tooltip={'Activated'}
              tooltip-position="top"
              className="bg--success radius--full p-l--sm  p-r--sm w-min--20 h-min--20 w-max--20 h-max--20  text--white d--flex justify-content--center align-items--center"
            >
              A
            </div>
          );
        } else {
          return (
            <div
              data-tooltip={'Deactivated'}
              tooltip-position="top"
              className="bg--secondary radius--full p-l--sm p-r--sm w-min--20 h-min--20 w-max--20 h-max--20 text--white d--flex justify-content--center align-items--center"
            >
              D
            </div>
          );
        }
      },
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'action',
      header: () => 'Action',
      cell: (props) => {
        const element = props?.row?.original;
        const initialDropList = [
          { title: 'Edit', value: 'edit' },
          { title: 'View', value: 'view' },
        ];
        if (element?.status === 'A') {
          initialDropList.push({
            title: 'Deactivate plan',
            value: 'deactivate',
          });
        } else {
          initialDropList.push({ title: 'Activate plan', value: 'activate' });
        }
        return (
          <div className="gap--xs w--full d--flex align-items--center justify-content--center">
            <Dropdown
              closeOnClickOutside={true}
              dropList={{
                component: ({ item }) => (
                  <span
                    className="w--full d--flex  justify-content--start  p--sm"
                    onClick={() => handleDropListClick(item, element)}
                  >
                    {item?.title}
                  </span>
                ),
                data: initialDropList,
              }}
              showcaret={false}
              extraClasses="w-min--150"
            >
              <div className="w-min--32 h-min--32 d--flex align-items--center justify-content--center radius--sm bg--black-100 text--black  c--pointer">
                <MoreVIcon width={15} height={15} />
              </div>
            </Dropdown>
          </div>
        );
      },
      meta: {
        colType: 'action',
        textAlign: 'center',
        width: '5%',
      },
    },
  ];
  return (
    <Fragment>
      <div className="px-3">
        <TableManager
          {...{
            name: 'Plans',
            fetcherKey: 'getPlans',
            fetcherFn: getPlans,
            columns,
            tableActions,
            shouldShowTotalCount: true,
          }}
        />
        {openSlider && (
          <AsideModal
            handleClose={() => {
              setRowData({});
              setOpenSlider(false);
              setIsView(false);
            }}
            title={
              isView ? 'View Plan' : rowData?.uuid ? 'Update Plan' : 'Add Plan'
            }
            footerComponent={null}
            width="900"
          >
            <AddPlan
              setOpenSlider={setOpenSlider}
              isView={isView}
              rowData={rowData}
              handleClose={() => {
                setRowData({});
                setOpenSlider(false);
                setIsView(false);
              }}
            />
          </AsideModal>
        )}
      </div>
    </Fragment>
  );
};

export default Plans;
