import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addPlan } from '../../api';
import { useAlert } from '../useAlert';

export default function useAddPlan() {
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();
  const Request = useMutation({
    mutationFn: addPlan,
    mutationKey: 'addPlan',
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries('getPlans');
      showAlert({
        type: 'success',
        message: data?.message ?? 'Plan added sucessfully',
      });
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...Request };
}
