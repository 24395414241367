import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import Sidebar from './Sidebar';
import { useQuery } from '@tanstack/react-query';
import { getAgentProfileDetails } from '../../api';

const UserDetails = () => {
  const { id } = useParams();

  const { data: userDetail } = useQuery({
    queryKey: ['get-agent-profile-details'],
    queryFn: () => getAgentProfileDetails({ uuid: id }),
    select: (data) => data?.data?.data,
  });

  return (
    <>
      <div className="d--flex gap--md h--full userDetails">
        <Sidebar userDetail={userDetail} />
        <Outlet />
      </div>
    </>
  );
};

export default UserDetails;
