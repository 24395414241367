import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FormInput from '../../../../../components/FormInput';
import Button from '../../../../../components/Button';
import MultiDropDown from '../../../../../components/MultiDropdown';
import PhoneInput from 'react-phone-number-input';
import { useQuery } from '@tanstack/react-query';
import { getUserAllDIDDropdown } from '../../../../../api';
import { useParams } from 'react-router-dom';
import useUpsertUserEmployee from '../../../../../hooks/useUpsertUserEmployee';

const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  country: 'US',
  phone: '',
  did_number: [],
};

const validationSchema = yup.object().shape({
  first_name: yup
    .string()
    .required('First name is required')
    .matches(/^[A-Za-z'-]+[ A-Za-z'-]*$/, 'First name cannot contain numbers'),
  last_name: yup
    .string()
    .required('Last name is required')
    .matches(/^[A-Za-z'-]+[ A-Za-z'-]*$/, 'Last name cannot contain numbers'),
  email: yup
    .string()
    .email('Must be a valid email')
    .required('Email is required'),
  phone: yup.string().required('Phone number is required'),
  did_number: yup.array().min(1, 'Select atleast one DID Number'),
});

export const RenderDIDItem = ({ item }) => {
  return (
    <option key={item.uuid} value={item?.did_number}>
      {item.did_number}
    </option>
  );
};
export default function AddMemberForm({
  editMemberInstance = {},
  handleClose = () => null,
}) {
  const { id = '' } = useParams();
  const { data: didListData } = useQuery({
    queryKey: ['getUserAllDIDDropdown'],
    queryFn: () => getUserAllDIDDropdown({ uuid: id }),
    select: (data) => data?.data?.data,
  });

  const {
    watch,
    getValues,
    setValue,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  });

  const { mutate: addMemberMutate, isLoading: addMemberLoad } =
    useUpsertUserEmployee({ handleClose, isEdit: editMemberInstance?.isEdit });

  useEffect(() => {
    const { isEdit = false, editData = {} } = editMemberInstance;
    if (
      isEdit &&
      editData &&
      Object.keys(editData) &&
      Object.keys(editData).length > 0
    ) {
      const {
        first_name = '',
        last_name = '',
        email,
        phone,
        country,
        assigned_did_array,
      } = editData;
      reset({
        first_name,
        last_name,
        email,
        country,
        phone,
        did_number: assigned_did_array || [],
      });
    }
  }, []);
  async function onSubmit(values) {
    const { isEdit = false, editData = {} } = editMemberInstance;

    const {
      first_name = '',
      last_name = '',
      email = '',
      phone = '',
      country = '',
      did_number = [],
    } = values;
    let payload = {
      first_name,
      last_name,
      country,
      phone,
      caller_id: [...did_number],
      email,
    };
    payload.uuid = id;
    if (isEdit) {
      payload.uuid = editData?.uuid;
    }
    addMemberMutate(payload);
  }

  const loading = isSubmitting || addMemberLoad;
  return (
    <form
      className="w--full h--full"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <div className="overflow--auto dialogScroll d--flex flex--column gap--md p--md">
        <div>
          <Controller
            name="first_name"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="text"
                label="First Name"
                placeholder="Enter your first name"
                maxLength={30}
                error={errors?.first_name?.message}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="last_name"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="text"
                label="Last Name"
                placeholder="Enter your last name"
                maxLength={30}
                error={errors?.last_name?.message}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="text"
                label="Email"
                placeholder="Enter your email address"
                error={errors?.email?.message}
              />
            )}
          />
        </div>
        <div>
          <label
            className={`label--control font--sm font--500 m-b--xs ${
              errors?.phone?.message ? 'text--danger' : 'text--black'
            }`}
          >
            {errors?.phone?.message || 'Phone'}
          </label>

          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <PhoneInput
                {...field}
                control={control}
                defaultCountry={watch('country')}
                international={true}
                addInternationalOption={false}
                withCountryCallingCode={true}
                countryCallingCodeEditable={false}
                placeholder="Enter your phone"
                internationalIcon={() => null}
                flagComponent={() => null}
                countrySelectComponent={() => null}
                value={getValues(`phone`)}
                limitMaxLength={true}
                onChange={(phone) => setValue(`phone`, phone)}
                className={`form--control w--full h-min--36  radius--sm p-l--md p-r--md  ${
                  errors?.phone?.message
                    ? 'border-full--danger'
                    : 'border-full--black-200'
                }`}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="did_number"
            control={control}
            render={({ field }) => (
              <MultiDropDown
                {...field}
                labelKey={'name'}
                valueKey={'uuid'}
                label="DID Number"
                optionList={didListData || []}
                placeholder={`${watch('did_number')?.length} number(s)`}
                error={errors?.did_number?.message}
              />
            )}
          />
        </div>
      </div>
      <div className="d--flex justify-content--between gap--md p-l--md p-r--md p-t--md border-top--primary-100">
        <Button
          type="button"
          size=""
          variant="contrast"
          color="primary"
          btnClasses="btn"
          onClick={() => handleClose()}
        >
          Cancel
        </Button>

        <Button
          type="submit"
          size=""
          variant="primary"
          color="white"
          btnClasses="btn"
          disabled={loading}
        >
          {loading ? 'Please wait..' : 'Save'}
        </Button>
      </div>
    </form>
  );
}
