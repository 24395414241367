import React from 'react';
import TableManager from '../../../../components/TableManager';
import { getAgentDIDNumbers } from '../../../../api';
import { useParams } from 'react-router-dom';
const DIDNumber = () => {
  const { id = '' } = useParams();
  const columns = [
    {
      accessorKey: 'did_number',
      header: () => 'Number',
      meta: {
        textAlign: 'center',
      },
    },

    {
      accessorKey: 'get_agency.name',
      header: () => 'Agency Company',
      cell: (props) => props?.getValue(),
      meta: {
        textAlign: 'left',
        width: '12%',
      },
    },
    {
      accessorKey: 'did_city',
      header: () => 'City',
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'did_country',
      header: () => 'Country',
    },
    {
      accessorKey: 'buy_date',
      header: () => 'Buy Date',
    },
    {
      accessorKey: 'renewal_date',
      header: () => 'Renewal Date',
    },
    {
      accessorKey: 'monthly_cost',
      header: () => 'Cost',
      cell: (props) => {
        return <b>${props.getValue()}</b>;
      },
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'status',
      header: () => 'Status',
      cell: (props) => {
        if (props?.getValue() === 'Y') {
          return (
            <div
              data-tooltip={'Activated'}
              tooltip-position="top"
              className="bg--success radius--full p-l--sm  p-r--sm w-min--20 h-min--20 w-max--20 h-max--20  text--white d--flex justify-content--center align-items--center"
            >
              A
            </div>
          );
        } else {
          return (
            <div
              data-tooltip={'Deactivated'}
              tooltip-position="top"
              className="bg--secondary radius--full p-l--sm p-r--sm w-min--20 h-min--20 w-max--20 h-max--20 text--white d--flex justify-content--center align-items--center"
            >
              D
            </div>
          );
        }
      },
      meta: {
        textAlign: 'left',
      },
    },
  ];
  return (
    <div className="w--full userDetailsCommon">
      <TableManager
        {...{
          name: 'Virtual Number',
          columns,
          fetcherFn: getAgentDIDNumbers,
          fetcherKey: 'getAgentDIDNumbers',
          extraClasses: 'table--responsive--full',
          extraParams: {
            uuid: id,
          },
          shouldShowTotalCount: true,
        }}
      />
    </div>
  );
};

export default DIDNumber;


