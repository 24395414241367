import React, { useState } from 'react';
import TableManager from '../../../../../components/TableManager';
import { getAgentAgents, userLogin } from '../../../../../api';
import { useParams } from 'react-router-dom';
import { memberAgentslabelsArray } from '../../../../../helpers/functions';
import AsideModal from '../../../../../components/AsideModal';
import AddAgentsForm from '../AddAgentsForm';
import Button from '../../../../../components/Button';
import useIcons from '../../../../../assets/icons/useIcons';
import Dropdown from '../../../../../components/Dropdown';
import ConfirmComponent from '../../../../../components/ConfirmComponent';
import useDeleteUserAgent from '../../../../../hooks/useDeleteUserAgent';

const initialEditInstance = { isEdit: false, editData: {} };

const Agents = ({ tab, setTab }) => {
  const { id } = useParams();
  const { MoreVIcon } = useIcons();
  const [addAgent, setAddAgent] = useState(false);
  const { mutate: memberDeleteAgent } = useDeleteUserAgent();
  const [editMemberInstance, setEditMemberInstance] =
    useState(initialEditInstance);

  const handleDropListClick = async ({ value }, data) => {
    switch (value) {
      case 'go_to_dashboard':
        const res = await userLogin({ uuid: data?.uuid });
        window.open(
          `${process.env.REACT_APP_PORTAL_URL}login-via-admin/${res?.data?.data}`,
        );
        break;

      default:
        break;
    }
  };

  const tableActions = [
    {
      id: 1,
      label: 'Add Agent',
      access: false,
      component: (
        <Button
          type="button"
          btnClasses="btn w-min--150"
          variant="primary"
          color="white"
          onClick={() => setAddAgent(true)}
        >
          Add Agent
        </Button>
      ),
    },
  ];
  const columns = [
    {
      accessorKey: 'first_name',
      header: () => 'First Name',
    },
    {
      accessorKey: 'last_name',
      header: () => 'Last Name',
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'email',
      header: () => 'Email',
    },
    {
      accessorKey: 'extension',
      header: () => 'Extension',
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'phone',
      header: () => 'Phone',
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'is_active',
      header: () => 'Status',
      cell: (props) => {
        if (props?.getValue() === 1) {
          return (
            <div
              data-tooltip={'Activated'}
              tooltip-position="top"
              className="bg--success radius--full p-l--sm  p-r--sm w-min--20 h-min--20 w-max--20 h-max--20  text--white d--flex justify-content--center align-items--center"
            >
              A
            </div>
          );
        } else {
          return (
            <div
              data-tooltip={'Deactivated'}
              tooltip-position="top"
              className="bg--secondary radius--full p-l--sm p-r--sm w-min--20 h-min--20 w-max--20 h-max--20 text--white d--flex justify-content--center align-items--center"
            >
              D
            </div>
          );
        }
      },
      meta: {
        width: '5%',
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'action',
      header: () => 'Action',
      cell: (props) => {
        const element = props?.row?.original;
        if (element?.is_active !== 1) return;
        return (
          <div className="gap--xs w--full d--flex align-items--center justify-content--center">
            <Dropdown
              closeOnClickOutside={true}
              dropList={{
                component: ({ item }) => (
                  <span
                    className="w--full d--flex  justify-content--start p--sm"
                    onClick={() => handleDropListClick(item, element)}
                  >
                    {item?.title}
                  </span>
                ),
                data: [{ title: 'Go to dashboard', value: 'go_to_dashboard' }],
              }}
              extraClasses="w-min--150"
              showcaret={false}
            >
              <div className="w-min--32 h-min--32 d--flex align-items--center justify-content--center radius--sm bg--black-100 text--black  c--pointer">
                <MoreVIcon width={15} height={15} />
              </div>
            </Dropdown>
          </div>
        );
      },
      meta: {
        colType: 'action',
        textAlign: 'center',
        width: '5%',
      },
    },
  ];
  const handleClose = () => {
    setEditMemberInstance({ isEdit: false, editData: {} });
    setAddAgent(false);
  };

  const handleAgentDelete = (data) => {
    const { uuid = '' } = data;
    memberDeleteAgent({ uuid });
  };

  return (
    <div className="w--full userDetailsCommon">
      <TableManager
        {...{
          name: 'Agents',
          columns,
          fetcherFn: getAgentAgents,
          fetcherKey: 'getAgentAgents',
          extraClasses: 'table--responsive--full',
          tableActions,
          extraParams: {
            uuid: id,
          },
          withTabs: true,
          tab,
          setTab,
          tabsLabels: memberAgentslabelsArray,
          shouldShowTotalCount: true,
          shouldFilter: true,
          initialFilterState: filterInitialValues,
        }}
      />
      {addAgent && (
        <AsideModal
          handleClose={handleClose}
          title={editMemberInstance?.isEdit ? 'Update Agent' : 'Add Agent'}
          footerComponent={null}
        >
          <AddAgentsForm
            editMemberInstance={editMemberInstance}
            handleClose={handleClose}
          />
        </AsideModal>
      )}
    </div>
  );
};

export default Agents;
const filterInitialValues = {
  filter: [
    {
      key: 'first_name',
      value: '',
      isChecked: false,
      inputPlaceholder: 'Enter first name',
      inputType: 'text',
      inputLabel: 'First Name',
    },
    {
      key: 'last_name',
      value: '',
      isChecked: false,
      inputPlaceholder: 'Enter last name',
      inputType: 'text',
      inputLabel: 'Last Name',
    },
    {
      key: 'email',
      value: '',
      isChecked: false,
      inputPlaceholder: 'Enter email',
      inputType: 'email',
      inputLabel: 'Email',
    },
    {
      key: 'phone',
      value: '',
      isChecked: false,
      inputPlaceholder: 'phone number',
      inputType: 'phone-number',
      inputLabel: 'Phone Number',
    },
  ],
};
