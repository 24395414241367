import React from 'react';
import { getInitials, stringToColour } from '../../helpers/functions';

export default function Avatar({ name, image, size = '36' }) {
  return (
    <div
      className={`text--primary radius--full  bg--primary-100 w-max--${size} w-min--${size}  h-max--${size} h-min--${size} d--flex align-items--center justify-content--center`}
    >
      {image ? (
        <img src={image} alt="profile" className="radius--full object--cover" />
      ) : (
        <span
          className="font--md"
          style={{
            color: stringToColour(name),
          }}
        >
          {getInitials(name)}
        </span>
      )}
    </div>
  );
}
