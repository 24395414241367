import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import TableManager from '../../../../../components/TableManager';
import MultiDropDown from '../../../../../components/MultiDropdown';
import { getAgentMembers, userLogin } from '../../../../../api';
import { memberAgentslabelsArray } from '../../../../../helpers/functions';
import Button from '../../../../../components/Button';
import AddMemberForm from '../AddMemberForm';

import AsideModal from '../../../../../components/AsideModal';
import useIcons from '../../../../../assets/icons/useIcons';
import Dropdown from '../../../../../components/Dropdown';
import ConfirmComponent from '../../../../../components/ConfirmComponent';
import useDeleteUserEmployee from '../../../../../hooks/useDeleteUserEmployee';
const initialEditInstance = { isEdit: false, editData: {} };

const Member = ({ tab, setTab }) => {
  const { id } = useParams();
  const [addMember, setAddMember] = useState(false);
  const [editMemberInstance, setEditMemberInstance] =
    useState(initialEditInstance);
  const { MoreVIcon } = useIcons();
  const { mutate: memberDeleteMutate } = useDeleteUserEmployee();

  const handleDropListClick = async ({ value }, data) => {
    switch (value) {
      case 'go_to_dashboard':
        const res = await userLogin({ uuid: data?.uuid });
        window.open(
          `${process.env.REACT_APP_PORTAL_URL}login-via-admin/${res?.data?.data}`,
        );
        break;

      default:
        break;
    }
  };

  const columns = [
    {
      accessorKey: 'first_name',
      header: () => 'First Name',
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'last_name',
      header: () => 'Last Name',
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'email',
      header: () => 'Email',
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'phone',
      header: () => 'Phone',
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'extension',
      header: () => 'Extension',
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'assigned_did_array',
      header: () => 'Virtual Number',
      cell: (props) => {
        const element = props?.row?.original || {};
        const { assigned_did_array = [], assigned_did = [] } = element;
        return (
          <>
            {assigned_did_array?.length === 0 ? (
              '---'
            ) : (
              <div className="w-max--120">
                <MultiDropDown
                  labelKey={'caller_id'}
                  valueKey={'caller_id'}
                  value={assigned_did_array ?? []}
                  placeholder={`${assigned_did_array?.length} number(s)`}
                  readOnly={true}
                  optionList={assigned_did || []}
                  withCaret={true}
                  height="28"
                  paddingRight="xs"
                  paddingLeft="xs"
                  extraClasses="no--focus link"
                  isSearchable={false}
                />
              </div>
            )}
          </>
        );
      },
    },

    {
      accessorKey: 'is_active',
      header: () => 'Status',
      cell: (props) => {
        if (props?.getValue() === 1) {
          return (
            <div
              data-tooltip={'Activated'}
              tooltip-position="top"
              className="bg--success radius--full p-l--sm  p-r--sm w-min--20 h-min--20 w-max--20 h-max--20  text--white d--flex justify-content--center align-items--center"
            >
              A
            </div>
          );
        } else {
          return (
            <div
              data-tooltip={'Deactivated'}
              tooltip-position="top"
              className="bg--secondary radius--full p-l--sm p-r--sm w-min--20 h-min--20 w-max--20 h-max--20 text--white d--flex justify-content--center align-items--center"
            >
              D
            </div>
          );
        }
      },
      meta: {
        width: '5%',
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'action',
      header: () => 'Action',
      cell: (props) => {
        const element = props?.row?.original;
        if (element?.is_active !== 1) return;

        return (
          <div className="gap--xs w--full d--flex align-items--center justify-content--center">
            <Dropdown
              closeOnClickOutside={true}
              dropList={{
                component: ({ item }) => (
                  <span
                    className="w--full d--flex  justify-content--start p--sm"
                    onClick={() => handleDropListClick(item, element)}
                  >
                    {item?.title}
                  </span>
                ),
                data: [{ title: 'Go to dashboard', value: 'go_to_dashboard' }],
              }}
              extraClasses="w-min--150"
              showcaret={false}
            >
              <div className="w-min--32 h-min--32 d--flex align-items--center justify-content--center radius--sm bg--black-100 text--black  c--pointer">
                <MoreVIcon width={15} height={15} />
              </div>
            </Dropdown>
          </div>
        );
      },
      meta: {
        colType: 'action',
        textAlign: 'center',
        width: '5%',
      },
    },
  ];

  const tableActions = [
    {
      id: 1,
      label: 'Add Employee',
      access: false,
      component: (
        <Button
          type="button"
          btnClasses="btn w-min--150"
          variant="primary"
          color="white"
          onClick={() => setAddMember(true)}
        >
          Add Employee
        </Button>
      ),
    },
  ];

  const handleClose = () => {
    setEditMemberInstance({ isEdit: false, editData: {} });
    setAddMember(false);
  };

  const handlMemberDelete = (data) => {
    const { uuid = '' } = data;
    memberDeleteMutate({ uuid });
  };

  return (
    <>
      <div className="w--full userDetailsCommon">
        <TableManager
          {...{
            extraClasses: 'table--responsive--full',
            name: 'Employees',
            columns,
            fetcherFn: getAgentMembers,
            fetcherKey: 'getAgentMembers',
            tableActions,
            withTabs: true,
            tab,
            setTab,
            extraParams: {
              uuid: id,
            },
            tabsLabels: memberAgentslabelsArray,
            shouldShowTotalCount: true,
            shouldFilter: true,
            initialFilterState: filterInitialValues,
          }}
        />
      </div>
      {addMember && (
        <AsideModal
          handleClose={handleClose}
          title={
            editMemberInstance?.isEdit ? 'Update Employee' : 'Add Employee'
          }
          footerComponent={null}
        >
          <AddMemberForm
            editMemberInstance={editMemberInstance}
            handleClose={handleClose}
          />
        </AsideModal>
      )}
    </>
  );
};

export default Member;

const filterInitialValues = {
  filter: [
    {
      key: 'first_name',
      value: '',
      isChecked: false,
      inputPlaceholder: 'Enter first name',
      inputType: 'text',
      inputLabel: 'First Name',
    },
    {
      key: 'last_name',
      value: '',
      isChecked: false,
      inputPlaceholder: 'Enter last name',
      inputType: 'text',
      inputLabel: 'Last Name',
    },
    {
      key: 'email',
      value: '',
      isChecked: false,
      inputPlaceholder: 'Enter email',
      inputType: 'email',
      inputLabel: 'Email',
    },
    {
      key: 'phone',
      value: '',
      isChecked: false,
      inputPlaceholder: 'Enter phone number',
      inputType: 'phone-number',
      inputLabel: 'Phone Number',
    },
  ],
};
