// import React from 'react';

// const FormInput = (
//   {
//     placeholder,
//     label,
//     error,
//     extraClasses = '',
//     radiusTopLeft = 'sm',
//     radiusBottomLeft = 'sm',
//     radiusTopRight = 'sm',
//     radiusBottomRight = 'sm',
//     height = '36',
//     ...rest
//   },
//   ref,
// ) => {
//   const text = error || label || '';
//   return (
//     <div className="w--full  d--flex flex--column">
//       {text && (
//         <label
//           className={`label--control font--sm font--500 m-b--xs  ${
//             error ? 'text--secondary' : 'text--black'
//           }`}
//         >
//           {text}
//         </label>
//       )}
//       <input
//         {...rest}
//         ref={ref}
//         placeholder={placeholder}
//         className={`form--control w--full h-min--${height} r-b-left--${radiusBottomLeft} r-t-left--${radiusTopLeft} r-b-right--${radiusBottomRight} r-t-right--${radiusTopRight} p-l--md p-r--md ${extraClasses} ${
//           error ? 'border-full--secondary' : 'border-full--black-200'
//         }`}
//       />
//     </div>
//   );
// };

// export default React.forwardRef(FormInput);

import React from 'react';

const FormInput = (
  {
    placeholder,
    label,
    error,
    extraClasses = '',
    paddingLeft = 'md',
    paddingRight = 'md',
    height = '36',
    border = true,
    ...rest
  },
  ref,
) => {
  const text = error || label || '';
  return (
    <div className="w--full  d--flex flex--column">
      {text && (
        <label
          className={`label--control font--sm font--500 m-b--xs d--flex w--full  ${
            error ? 'text--danger' : 'text--black'
          }`}
        >
          {text}
        </label>
      )}
      <input
        {...rest}
        ref={ref}
        placeholder={placeholder}
        className={`form--control w--full h-min--${height}  radius--sm p-l--${paddingLeft} p-r--${paddingRight} ${extraClasses} ${
          error
            ? 'border-full--danger'
            : border
            ? 'border-full--black-200'
            : 'border-full--transparent'
        }`}
      />
    </div>
  );
};

export default React.forwardRef(FormInput);
