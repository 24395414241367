import React from 'react';
import TableManager from '../../../../components/TableManager';
import { getAgentCalls } from '../../../../api';
import { useParams } from 'react-router-dom';
import {
  DateFilterTypes,
  durationToMMSS,
  formatDate,
} from '../../../../helpers/functions';

const Call = () => {
  const { id } = useParams();
  const columns = [
    {
      accessorKey: 'start_stamp',
      header: () => 'Date',
      cell: (props) => formatDate(props?.getValue()),
      meta: {
        textAlign: 'center',
      },
    },
    // {
    //   accessorKey: 'accountcode',
    //   header: () => 'Account No',
    //   cell: (props) => props?.row?.original?.user?.account_number,
    //   meta: {
    //     textAlign: 'center',
    //   },
    // },
    // {
    //   accessorKey: 'user.email',
    //   header: () => 'Email',
    // },
    {
      accessorKey: 'caller_id_number',
      header: () => 'From',
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'destination_number',
      header: () => 'To',
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'via_did',
      header: () => 'Via Virtual No',
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'direction',
      header: () => 'Call Type',
    },
    {
      accessorKey: 'forward_type',
      header: () => 'Forwarding Type',
    },
    {
      accessorKey: 'duration',
      header: () => 'Duration',
      cell: (props) => durationToMMSS(props.getValue()),
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'charge',
      header: () => 'Charge($)',
      meta: {
        textAlign: 'center',
      },
    },
  ];

  const filterInitialValues = {
    filter: [
      {
        key: 'account_number',
        value: '',
        isChecked: false,
        inputPlaceholder: 'Enter account number',
        inputType: 'number',
        inputLabel: 'Account number',
      },

      {
        key: 'caller_id_number',
        value: '',
        isChecked: '',
        inputPlaceholder: 'Enter from',
        inputType: 'text',
        inputLabel: 'From',
      },
      {
        key: 'destination_number',
        value: '',
        isChecked: '',
        inputPlaceholder: 'Enter virtual number',
        inputType: 'text',
        inputLabel: 'Virtual number',
      },

      {
        key: 'direction',
        value: '',
        isChecked: '',
        inputPlaceholder: 'Enter call type',
        inputType: 'select',
        inputLabel: 'Call type',
        inputOptions: [
          { label: 'Inbound', value: 'inbound' },
          { label: 'Outbound', value: 'outbound' },
          { label: 'Missed', value: 'missed' },
        ],
      },
      // {
      //   key: 'date',
      //   value: {
      //     form: '',
      //     to: '',
      //   },
      //   inputType: 'date',
      //   dateType: '',
      //   dateFilterTypes: DateFilterTypes,
      // },
    ],
  };

  return (
    <div className="w--full userDetailsCommon">
      <TableManager
        {...{
          name: 'Calls',
          columns,
          fetcherFn: getAgentCalls,
          fetcherKey: 'getAgentCalls',
          extraClasses: 'table--responsive--full',
          extraParams: {
            uuid: id,
          },
          shouldShowTotalCount: true,
          shouldFilter: true,
          initialFilterState: filterInitialValues,
          customFilters: true,
        }}
      />
    </div>
  );
};

export default Call;
