import { useMutation } from '@tanstack/react-query';
import { providerListing } from '../../api';
import { useAlert } from '../useAlert';

export default function useProviderListing() {
  const { showAlert } = useAlert();
  const Request = useMutation({
    mutationFn: providerListing,
    mutationKey: 'providerListing',

    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...Request };
}
