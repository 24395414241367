import React, { useEffect } from 'react';
// import MeetingGraph from './Components/MeetingGraph';
// import BillingGraph from './Components/BillingGraph';
// import UserGraph from './Components/UserGraph';
import useAdminDashboardStatus from '../../hooks/useAdminDashboardStatus';
import UserGraph from './Components/UserGraph';
import MeetingGraph from './Components/MeetingGraph';
import BillingGraph from './Components/BillingGraph';

const Dashboard = () => {
  const { data: dashboardData, mutate } = useAdminDashboardStatus();
  useEffect(() => {
    mutate();
  }, []);
  return (
    <>
      <div className="w--full">
        <div
          className="page_heading page_heading_enhence d-flex justify-content-between
         align-items-center w--full p-1 rounded-0"
        >
          <h3 className="d-flex align-items-center mb-0 h-min--36 d--flex align-items--center">
            <span className="font--md font--600">Dashboard</span>
          </h3>
        </div>
        <div className="w--full d--flex flex--column gap--md dashboardSec overflow--auto">
          <div className="w--full dash-chart  bg--white radius--sm p--sm  ">
            <UserGraph graphData={dashboardData?.data?.data?.userGraph} />
          </div>
          <div className="w--full  bg--white radius--sm p--sm">
            <BillingGraph graphData={dashboardData?.data?.data?.billingGraph} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
