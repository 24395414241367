'use client';
import React from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import useIcons from '../../assets/icons/useIcons';

export default function Sidebar() {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    GroupIcon,
    BackRoundIcon,
    ProfileIcon,
    BuildingIcon,
    HeadPhoneIcon,
    CallIcon,
    BillingIcon,
  } = useIcons();
  const tabs = [
    {
      id: 1,
      label: 'All Calls',
      icon: ProfileIcon,
      path: 'all-calls',
    },
    {
      id: 2,
      label: 'Voicemails',
      icon: ProfileIcon,
      path: 'voicemails',
    },
    {
      id: 3,
      label: 'Recordings',
      icon: ProfileIcon,
      path: 'recordings',
    },
    {
      id: 4,
      label: 'Missed',
      icon: ProfileIcon,
      path: 'missed-calls',
    },
    {
      id: 4,
      label: 'Incoming',
      icon: ProfileIcon,
      path: 'incoming-calls',
    },
    {
      id: 4,
      label: 'Outgoing',
      icon: ProfileIcon,
      path: 'outgoing-calls',
    },
  ];

  const handleActiveClass = (active) => {
    if (active === location.pathname.split('/').at(-1)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <aside className="d--flex w--full w-max--200 h--full bg--transparent radius--sm text--white p-t--sm p-r--md p-l--md p-b--md flex--column gap--sm">
      <div className="w--full d--flex  justify-content--start align-items--start font--sm font--600 text--primary m-b--md"></div>
      <div className="d--flex flex--column gap--md w--full h--full">
        {tabs.map(({ id, label, icon: Icon, path }) => {
          return (
            <NavLink
              key={id}
              to={path}
              className={`${
                handleActiveClass(path)
                  ? 'bg--primary text--white'
                  : ' text--black'
              }  d--flex align-items--center justify-content--start  d--flex gap--sm p--sm radius--sm h-min--36`}
            >
              {label}
            </NavLink>
          );
        })}
      </div>
    </aside>
  );
}
