import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateRateCard } from '../../api';
import { useAlert } from '../useAlert';

export default function useRateCardUpdate() {
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();
  const Request = useMutation({
    mutationFn: updateRateCard,
    mutationKey: 'updateRateCard',
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries('rateCardList');
      queryClient.invalidateQueries('providerListing');
      showAlert({
        type: 'success',
        message: data?.message ?? 'Ratecard updated successfully',
      });
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...Request };
}
