import * as yup from 'yup';

export const validationSchema = yup.object({
  name: yup
    .string()
    .required('Provider name is required')
    .max(25, 'max length can be 25 character'),
  host_ip_outbound: yup.string().required('Outbound IP host is required'),
  host_ip_inbound: yup.string().required('Inbound IP host is required'),
  allow_codecs: yup
    .array()
    .min(1, 'Please select atleast one code')
    .typeError('Please select atleast one code'),
  rate_card_uuid: yup
    .string()
    .required('Please select atleast one card')
    .typeError('Please select atleast one card'),
});

export const intialValues = {
  name: '',
  add_prefix: '',
  remove_prefix: '',
  host_ip_outbound: '',
  host_ip_inbound: '',
  allow_codecs: '',
  rate_card_uuid: '',
};
export const codecsOption = [
  { label: 'pcmu', value: 'pcmu' },
  { label: 'opus', value: 'opus' },
  { label: 'psma', value: 'psma' },
  { label: 'gsm', value: 'gsm' },
];
