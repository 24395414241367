import { useMutation } from '@tanstack/react-query';
import { getAllUsers } from '../../api';
import { useAlert } from '../useAlert';

export default function useGetAllUsers() {
  const { showAlert } = useAlert();
  const Request = useMutation({
    mutationFn: getAllUsers,
    mutationKey: 'getAllUsers',
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...Request };
}
