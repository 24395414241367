import { useMutation } from '@tanstack/react-query';
import { adminDashboardStatus } from '../../api';
import { useAlert } from '../useAlert';

export default function useAdminDashboardStatus() {
  const { showAlert } = useAlert();
  const logoutRequest = useMutation({
    mutationFn: adminDashboardStatus,
    mutationKey: 'adminDashboardStatus',

    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...logoutRequest };
}
