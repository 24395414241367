import React, { useRef, useState } from 'react';
import ControlledToolTip from '../ControlledToolTip';
import useClickOutside from '../../hooks/useClickOutside';

export default function CustomToolTip({
  options = [],
  id,
  separatorComponent: SeparatorComponent = () => null,
  controlled = false,
  enableTooltip = false,
  children,
}) {
  const [show, setShow] = useState(false);
  const toolTipRef = useRef(null);
  const toolTipDivRef = useRef(null);

  useClickOutside([toolTipDivRef, toolTipRef], () => {
    enableTooltip && setShow(false);
  });

  return (
    <span
      className="position--relative d--flex align-items--center"
      key={id}
      ref={toolTipDivRef}
      onMouseEnter={() => !controlled && enableTooltip && setShow(true)}
      onMouseLeave={() => !controlled && enableTooltip && setShow(false)}
    >
      <span
        className="c--pointer"
        onClick={() => enableTooltip && setShow(true)}
      >
        {children}
      </span>
      {controlled && show && (
        <ControlledToolTip
          ref={toolTipRef}
          title={<SeparatorComponent list={options} setShow={setShow} />}
        />
      )}
      {!controlled && show && (
        <ControlledToolTip
          ref={toolTipRef}
          title={<SeparatorComponent list={options} setShow={setShow} />}
        />
      )}
    </span>
  );
}
