import React from 'react';
import TableManager from '../../components/TableManager';
import { didListing } from '../../api';
import useReleaseDid from '../../hooks/useReleaseDid';
import useIcons from '../../assets/icons/useIcons';
import Dropdown from '../../components/Dropdown';
import { DateFilterTypes } from '../../helpers/functions';

const VirtualNumber = () => {
  const { mutate: mutateReleaseDid } = useReleaseDid();
  const { MoreVIcon } = useIcons();
  const deleteUserList = (data) => {
    mutateReleaseDid({ uuid: data?.uuid });
  };

  const filterInitialValues = {
    filter: [
      {
        key: 'account_number',
        value: '',
        isChecked: false,
        inputPlaceholder: 'Enter account number',
        inputType: 'number',
        inputLabel: 'Account number',
      },

      {
        key: 'did_number',
        value: '',
        isChecked: '',
        inputPlaceholder: 'Enter number',
        inputType: 'phone-number',
        inputLabel: 'Number',
      },
      {
        key: 'tenant_company',
        value: '',
        isChecked: '',
        inputPlaceholder: 'Enter tenant company',
        inputType: 'text',
        inputLabel: 'Tenant company',
      },
      {
        key: 'agency_name',
        value: '',
        isChecked: '',
        inputPlaceholder: 'Enter agency company',
        inputType: 'text',
        inputLabel: 'Agency company',
      },
      // {
      //   key: 'date',
      //   value: {
      //     form: '',
      //     to: '',
      //   },
      //   inputType: 'date',
      //   dateType: '',
      //   dateFilterTypes: DateFilterTypes,
      // },
    ],
  };

  const columns = [
    {
      accessorKey: 'user_uuid',
      header: () => 'Account No',
      meta: {
        textAlign: 'center',
      },
      cell: (props) => {
        const {
          account_number = '',
          first_name = '',
          email = '',
        } = props?.row?.original?.get_user || {};
        return (
          <span
            data-tooltip={`
                ${first_name}
                ${email}
                ${props?.row?.original?.did_number}
              `}
            tooltip-position="right"
            className="c--pointer text--danger font--600 d--flex gap--sm align-items--center justify-content--center"
          >
            {account_number}
          </span>
        );
      },
    },
    {
      accessorKey: 'did_number',
      header: () => 'Number',
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'did_city',
      header: () => 'City',
      meta: {
        textAlign: 'left',
      },
    },

    {
      accessorKey: 'did_country',
      header: () => 'Country',
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'get_user',
      header: () => 'Tenant Company',
      cell: (props) => props?.row?.original?.get_user?.tenant_company || '---',
      meta: {
        textAlign: 'left',
        width: '10%',
      },
    },
    {
      accessorKey: 'get_agency',
      header: () => 'Agency Company',
      cell: (props) => props?.row?.original?.get_agency?.name || '---',
      meta: {
        textAlign: 'left',
        width: '12%',
      },
    },

    {
      accessorKey: 'buy_date',
      header: () => 'Buy Date',
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'renewal_date',
      header: () => 'Renewal Date',
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'monthly_cost',
      header: () => 'Cost',
      cell: (props) => {
        return <b>${props.getValue()}</b>;
      },
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'status',
      header: () => 'Status',
      cell: (props) => {
        if (props?.getValue() === 'Y') {
          return (
            <div
              data-tooltip={'Active'}
              tooltip-position="top"
              className="bg--success radius--full p-l--sm  p-r--sm w-min--20 h-min--20 w-max--20 h-max--20  text--white d--flex justify-content--center align-items--center"
            >
              A
            </div>
          );
        } else {
          return (
            <div
              data-tooltip={'Deactive'}
              tooltip-position="top"
              className="bg--secondary radius--full p-l--sm p-r--sm w-min--20 h-min--20 w-max--20 h-max--20 text--white d--flex justify-content--center align-items--center"
            >
              D
            </div>
          );
        }
      },
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'action',
      header: () => 'Action',
      cell: (props) => {
        const element = props?.row?.original;
        const dropDownOptions = [{ title: 'Delete', value: 'delete' }];
        return (
          <div className="gap--xs w--full d--flex align-items--center justify-content--center">
            <Dropdown
              closeOnClickOutside={true}
              showcaret={false}
              dropList={{
                component: ({ item }) => (
                  <div
                    className="w--full d--flex  justify-content--start p--sm"
                    onClick={() => {
                      if (item?.value === 'delete') {
                        deleteUserList(element);
                      }
                    }}
                  >
                    {item?.title}
                  </div>
                ),
                data: dropDownOptions,
              }}
              extraClasses="w-min--150"
            >
              <div className="w-min--32 h-min--32 d--flex align-items--center justify-content--center radius--sm bg--black-100 text--black  c--pointer">
                <MoreVIcon width={15} height={15} />
              </div>
            </Dropdown>
          </div>
        );
      },
      meta: {
        colType: 'action',
        textAlign: 'center',
        width: '5%',
      },
    },
  ];
  return (
    <>
      <div className="px-3 ">
        <TableManager
          {...{
            name: 'Virtual number',
            fetcherKey: 'did-listing',
            fetcherFn: didListing,
            columns,
            initialValues: filterInitialValues,
            shouldFilter: true,
            initialFilterState: filterInitialValues,
            shouldShowTotalCount: true,
            customFilters: true,
            enableDefaultFilter: 'Show All',
          }}
        />
      </div>
    </>
  );
};

export default VirtualNumber;
