import React, { Fragment, useState } from 'react';
import useIcons from '../../assets/icons/useIcons';
import useRateCardDelete from '../../hooks/useRateCardDelete';
import { formatDate } from '../../helpers/functions';
import { rateCardList } from '../../api';
import TableManager from '../../components/TableManager';
import Dropdown from '../../components/Dropdown';
import AsideModal from '../../components/AsideModal';
import AddRateCard from './Modal/AddRateCard';
import Button from '../../components/Button';
import CallRateDetails from './Modal/CallRateDetails';

const RateCard = () => {
  const [openSlider, setOpenSlider] = useState(false);
  const [rowData, setRowData] = useState({});
  const { MoreVIcon } = useIcons();
  const { mutate: mutateRateCardDelete } = useRateCardDelete();
  const tableActions = [
    {
      id: 1,
      component: (
        <Button
          type="button"
          btnClasses="btn w-min--150"
          variant="primary"
          color="white"
          onClick={() => {
            setOpenSlider(true);
            setRowData({});
          }}
        >
          Add Ratecard
        </Button>
      ),
    },
  ];

  const handleEditData = (data) => {
    setOpenSlider(true);
    setRowData(data);
  };
  const deleteUserList = (data) => {
    mutateRateCardDelete({ uuid: data?.uuid });
  };

  const handleDropdownClick = ({ value = '' }, data) => {
    switch (value) {
      case 'edit':
        handleEditData(data);
        break;
      case 'delete':
        deleteUserList(data);
        break;
      default:
        break;
    }
  };

  const columns = [
    {
      accessorKey: 'created_at',
      header: () => 'Date',
      cell: (props) => formatDate(props.getValue()),
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'name',
      header: () => 'Name',
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'total_rates',
      header: () => 'Rate',
      meta: {
        textAlign: 'center',
      },
      cell: (props) => (
        <span
          className="text-info pointer"
          onClick={() => {
            setOpenSlider('rates-details');
            setRowData(props?.row?.original);
          }}
        >
          {props.getValue()}
        </span>
      ),
    },
    {
      accessorKey: 'type',
      header: () => 'Type',
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'service',
      header: () => 'Service',
      meta: {
        textAlign: 'left',
      },
    },

    {
      accessorKey: 'action',
      header: () => 'Action',
      cell: (props) => {
        const element = props?.row?.original || {};
        const dropDownOptions = [
          { title: 'Edit', value: 'edit' },
          { title: 'Delete', value: 'delete' },
        ];
        return (
          <div className="gap--xs w--full d--flex align-items--center justify-content--center">
            <Dropdown
              closeOnClickOutside={true}
              showcaret={false}
              dropList={{
                component: ({ item }) => (
                  <div
                    className="w--full d--flex  justify-content--start p--sm"
                    onClick={() => {
                      handleDropdownClick(item, element);
                    }}
                  >
                    {item?.title}
                  </div>
                ),
                data: dropDownOptions,
              }}
              extraClasses="w-min--150"
            >
              <div className="w-min--32 h-min--32 d--flex align-items--center justify-content--center radius--sm bg--black-100 text--black  c--pointer">
                <MoreVIcon width={15} height={15} />
              </div>
            </Dropdown>
          </div>
        );
      },
      meta: {
        colType: 'action',
        textAlign: 'center',
        width: '5%',
      },
    },
  ];
  return (
    <Fragment>
      <div className="px-3">
        <TableManager
          {...{
            name: 'Rate Card',
            fetcherKey: 'rateCardList',
            fetcherFn: rateCardList,
            columns,
            tableActions,
            shouldShowTotalCount: true,
          }}
        />
        {openSlider && (
          <AsideModal
            handleClose={() => {
              setRowData({});
              setOpenSlider(false);
            }}
            title={
              openSlider === 'rates-details'
                ? 'Call Rate Details'
                : rowData?.uuid
                ? 'Update Rate Card'
                : 'Add Rate Card'
            }
            footerComponent={null}
            width={openSlider === 'rates-details' ? '900' : '500'}
          >
            {openSlider === 'rates-details' ? (
              <CallRateDetails
                {...{
                  rowData,
                }}
              />
            ) : (
              <AddRateCard
                {...{
                  setOpenSlider,
                  rowData,
                }}
              />
            )}
          </AsideModal>
        )}
      </div>
    </Fragment>
  );
};

export default RateCard;
