import React, { useEffect, useRef } from 'react';
import useIcons from '../../assets/icons/useIcons';
export default function Modal({
  children,
  handleClose,
  title,
  shouldCloseOnClickOutside = false,
  footerComponent: FooterComponent = () => null,
  headerComponent: HeaderComponent = () => null,
  size = '500',
  height = '500',
  radius = 'sm',
}) {
  const { CrossRoundIcon } = useIcons();
  const modalRef = useRef();
  const modalBodyRef = useRef();

  useEffect(() => {
    if (modalRef.current) {
      const refrence = modalRef.current;
      refrence.showModal();
    }
  }, [modalRef]);

  return (
    <div className="position--fixed left--0 top--0 w--full h--full bg--black-400 z-index--sm">
      <dialog
        data-modal
        ref={modalRef}
        className={`dialog-main w--xs h-min--${height} w-min--${size}  w-max--${size} m--auto border-full--black-100 radius--${radius}`}
        onClick={(e) => {
          if (!shouldCloseOnClickOutside) {
            e.preventDefault();
            e.stopPropagation();
          } else {
            handleClose();
          }
        }}
        onClose={() => handleClose()}
      >
        {HeaderComponent && (
          <dialog-head class="dialog-head h-min--50 p-l--md p-r--md d--flex align-items--center justify-content--between border-bottom--black-100">
            {<div className="font--md font--600">{title}</div>}
            <button
              type="button"
              className=" font--md font--600 text--primary d--flex c--pointer radius--full"
              onClick={() => handleClose()}
            >
              <CrossRoundIcon width={30} height={30} />
            </button>
          </dialog-head>
        )}

        <dialog-body ref={modalBodyRef} class="dialog-body p--md h--full">
          {children}
        </dialog-body>

        {FooterComponent && (
          <dialog-footer class="modal-foot h-max--50 h-min--50 p-l--md p-r--md d--flex align-items--center justify-content--between border-top--black-100">
            {FooterComponent}
          </dialog-footer>
        )}
      </dialog>
    </div>
  );
}
