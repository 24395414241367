import React, { Fragment, useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import useIcons from '../../../assets/icons/useIcons';
import useGetAllUsers from '../../../hooks/useGetAllUsers';
import {
  getCalls,
  getParticularCallHistory,
  getUserAllContactListing,
  getUserAllDIDDropdown,
  getUserAllIVRListing,
  getUserAllMemberAgentDropdown,
  getUserAllRingGroupListing,
} from '../../../api';
import {
  capitalizeWords,
  converDateTimeFormateApis,
  durationToMMSS,
  formatPhoneNumber,
} from '../../../helpers/functions';
import AudioPlayer from '../../../components/AudioPlayer';
import TableManager from '../../../components/TableManager';
import CustomToolTip from '../../../components/CustomToolTip';
import { useOutletContext } from 'react-router-dom';
import useAllNumberDetails from '../../../hooks/useAllNumberDetails';

const RenderSearchItem = ({ item }) => {
  return (
    <option key={item.uuid} value={item?.uuid}>
      {item?.name}
    </option>
  );
};

const SeparatorItem = ({ list = [] }) => {
  const result =
    list?.map((option) => {
      return (
        <div
          key={option?.uuid}
          className="d--flex align-items--center gap--sm "
        >
          {option?.name}
        </div>
      );
    }) ?? null;

  return (
    <div>
      <div className="d--flex flex--column gap--sm h-max--350">{result}</div>
    </div>
  );
};

const Voicemail = () => {
  const {
    IncomingCall,
    OutgoingCall,
    MissedCall,
    VoicemailIcon,
    VoicemailGroupIcon,
    UsersIcon,
    HoldIcon,
    PlayIcon,
    MobileIcon,
  } = useIcons();
  const { selected_user_id } = useOutletContext();
  const [playingData, setPlayingData] = useState({
    playing: false,
    file: null,
    user_id: '',
  });

  const { getNumberName } = useAllNumberDetails({ uuid: selected_user_id });

  const { data: getUserDidListing = [] } = useQuery({
    queryKey: ['getUserAllDIDDropdown', selected_user_id],
    queryFn: () => getUserAllDIDDropdown({ uuid: selected_user_id }),
    select: (data) => data?.data?.data,
    enabled: Boolean(selected_user_id),
    cacheTime: Infinity,
    keepPreviousData: true,
    refetchOnMount: false,
    staleTime: Infinity,
  });
  const { data: userIVRListing = [] } = useQuery({
    queryKey: ['getUserAllIVRListing', selected_user_id],
    queryFn: () => getUserAllIVRListing({ uuid: selected_user_id }),
    select: (data) => data?.data?.data,
    enabled: Boolean(selected_user_id),
    cacheTime: Infinity,
    keepPreviousData: true,
    refetchOnMount: false,
    staleTime: true,
  });
  const { data: userContactListing = [] } = useQuery({
    queryKey: ['getUserAllContactListing', selected_user_id],
    queryFn: () => getUserAllContactListing({ uuid: selected_user_id }),
    select: (data) => data?.data?.data,
    enabled: Boolean(selected_user_id),
    cacheTime: Infinity,
    keepPreviousData: true,
    refetchOnMount: false,
    staleTime: true,
  });
  const { data: userRingGroupListing = [] } = useQuery({
    queryKey: ['getUserAllRingGroupListing', selected_user_id],
    queryFn: () => getUserAllRingGroupListing({ uuid: selected_user_id }),
    select: (data) => data?.data?.data,
    enabled: Boolean(selected_user_id),
    cacheTime: Infinity,
    keepPreviousData: true,
    refetchOnMount: false,
    staleTime: true,
  });
  const { data: userMemberAgentListing = [] } = useQuery({
    queryKey: ['getUserAllMemberAgentDropdown', selected_user_id],
    queryFn: () => getUserAllMemberAgentDropdown({ uuid: selected_user_id }),
    select: (data) => data?.data?.data,
    enabled: Boolean(selected_user_id),
    cacheTime: Infinity,
    keepPreviousData: true,
    refetchOnMount: false,
    staleTime: true,
  });

  const filterInitialValues = {
    filter: [
      {
        key: 'name',
        value: '',
        isChecked: false,
        inputPlaceholder: 'Enter Name/Number',
        inputType: 'custom-selects-name-number',
        inputLabel: 'Name/Number',
        inputOptions: userContactListing?.map((item) => {
          return {
            value: item?.phone,
            name: item?.name,
          };
        }),
      },
      {
        key: 'extension',
        value: '',
        isChecked: false,
        inputPlaceholder: 'Enter Extension Number',
        inputType: 'multi-select',
        inputLabel: 'Extension',
        inputOptions: userMemberAgentListing.map((item) => {
          return {
            label: item?.name,
            name: item?.name,
            value: item?.extension,
            profile_pic: item?.profile_pic,
            extension: item?.extension,
          };
        }),
      },
      {
        key: 'did_number',
        value: '',
        isChecked: false,
        inputPlaceholder: 'Enter virtual number',
        inputType: 'select',
        inputLabel: 'Virtual Number',
        inputOptions: getUserDidListing?.map((item) => {
          return {
            label: `${formatPhoneNumber(item?.name)}`,
            value: item?.uuid,
          };
        }),
      },
      {
        key: 'department',
        value: '',
        isChecked: false,
        inputPlaceholder: 'Enter Department',
        inputType: 'select',
        inputLabel: 'Department',
        inputOptions: userRingGroupListing?.map((item) => {
          return {
            label: item?.name,
            value: item?.uuid,
          };
        }),
      },

      {
        key: 'duration',
        value: '',
        isChecked: false,
        inputPlaceholder: 'Enter Duration',
        inputType: 'select',
        inputLabel: 'Duration',
        inputOptions: [
          {
            value: '0-2',
            label: '0-2',
          },
          {
            value: '2-5',
            label: '2-5',
          },
          {
            value: '5-10',
            label: '5-10',
          },
          {
            value: '10-50',
            label: '10-50',
          },
          {
            value: '50',
            label: '50',
          },
        ],
      },
    ],
  };

  const callIconLookup = {
    Missed: (
      <span className="text--danger">
        <MissedCall width={16} height={16} />
      </span>
    ),
    Outbound: (
      <span className="text--success">
        <OutgoingCall width={16} height={16} />
      </span>
    ),
    Inbound: (
      <span className="text--primary">
        <IncomingCall width={16} height={16} />
      </span>
    ),
  };

  function handleMembers(members = '') {
    if (!members) return '';
    const extensions = members?.split(',');
    const names = [];
    for (let index = 0; index < extensions.length; index++) {
      const element = extensions[index];

      names.push(getNumberName(element, false));
      // names.push(element);
    }
    return ` (${names.join(',')})`;
  }

  const getStatusItem = (status = '') => {
    switch (status) {
      case 'SUCCESS':
        return (
          <span
            style={{ textTransform: 'capitalize' }}
            className="text--success"
          >
            {status?.toLowerCase()}
          </span>
        );
      case 'CANCEL':
        return (
          <span
            style={{ textTransform: 'capitalize' }}
            className="text--danger"
          >
            {status?.toLowerCase()}
          </span>
        );

      default:
        return (
          <span
            style={{ textTransform: 'capitalize' }}
            className="text--secondary white--space-none"
          >
            {status?.replaceAll('_', ' ')?.toLowerCase()}
          </span>
        );
    }
  };

  const columns = [
    {
      accessorKey: 'start_stamp',
      header: () => 'Date/Time',
      cell: (props) => {
        const element = props?.row?.original;
        const direction = element?.direction;
        const { status = '', forward_type = '' } = element || {};

        return (
          <div className="d--flex align-items--center  justify-content--start gap--sm">
            {['VOICEMAIL'].includes(status) ? (
              <>
                {forward_type === 'VOICEMAIL' && (
                  <VoicemailIcon width={20} height={20} />
                )}
                {forward_type === 'VOICEMAILGROUP' && (
                  <VoicemailGroupIcon width={20} height={20} />
                )}
              </>
            ) : (
              callIconLookup?.[direction] ?? ''
            )}
            {converDateTimeFormateApis(props?.row?.original?.start_stamp)}
          </div>
        );
      },
      meta: {
        width: '21%',
      },
    },
    {
      accessorKey: 'caller_id_number',
      header: () => 'From',
      cell: (props) => {
        return (
          <div className="d--flex flex--column">
            <div>
              {getNumberName(props?.getValue(), false)?.includes('+')
                ? formatPhoneNumber(props?.getValue())
                : getNumberName(props?.getValue(), false)}
            </div>
            {props?.getValue()?.length < 5 && (
              <div className="font--xs">{`Ext. ${props?.getValue()}`}</div>
            )}
          </div>
        );
      },
      meta: {
        width: '20%',
      },
    },
    {
      accessorKey: 'via_did',
      header: () => 'Via Virtual Number',
      cell: (props) => {
        const element = props?.row?.original;
        return (
          <>
            <div className="d--flex flex--column justify-content--start align-items--center">
              {element?.did_name && <div>{element?.did_name}</div>}
              <div>{formatPhoneNumber(props?.getValue())}&nbsp;</div>
            </div>
          </>
        );
      },
      meta: {
        width: '12%',
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'accountcode',
      header: () => 'To',
      cell: (props) => {
        const element = props?.row?.original;
        let members = [];
        if (element?.forward_type && element?.members) {
          members = element?.members?.split(',')?.map((item) => {
            return {
              name: getNumberName(item, false),
              // name: '',
              extension: item,
            };
          });
        }
        let departmentName = '';
        if (
          element?.forward_type === 'DEPARTMENT' ||
          element?.forward_type === 'VOICEMAILGROUP'
        ) {
          departmentName =
            userRingGroupListing?.find(
              (item) => item?.uuid === element?.forward_value,
            )?.name || 'Unknown';
        }
        let ivrName = '';
        if (element?.forward_type === 'IVR') {
          ivrName =
            userIVRListing?.find(
              (item) => item?.uuid === element?.forward_value,
            )?.name || 'Unknown';
        }

        return (
          <>
            {element?.is_voicemail === 1 ? (
              <span className="d--flex align-items--center  justify-content--start gap--sm">
                <span>
                  {element?.forward_type === 'VOICEMAILGROUP' ? (
                    <VoicemailGroupIcon width={20} height={20} />
                  ) : (
                    <VoicemailIcon width={20} height={20} />
                  )}
                </span>
                <div>
                  {element?.forward_type === 'VOICEMAILGROUP' ? (
                    element?.forward_name ? (
                      <CustomToolTip
                        id={props?.row?.id}
                        options={members}
                        accessorKey="extension"
                        separatorComponent={SeparatorItem}
                        controlled={true}
                        enableTooltip={members?.length > 0}
                      >
                        <span className="d--flex align-items--center gap--xs">
                          <span>{element?.forward_name}</span>
                        </span>
                      </CustomToolTip>
                    ) : (
                      'Department Voicemail'
                    )
                  ) : element?.forward_type === 'VOICEMAIL' ? (
                    element?.forward_name ? (
                      <span className="d--flex align-items--center gap--xs">
                        <span>
                          {element?.forward_name}
                          <div className="font--xs">{`Ext. ${element?.forward_value}`}</div>
                        </span>
                      </span>
                    ) : (
                      'Voicemail'
                    )
                  ) : (
                    capitalizeWords(element?.forward_type?.toLowerCase(), ' ')
                  )}
                </div>
              </span>
            ) : (
              <>
                {element?.destination_number ? (
                  <>
                    <span
                      className="d--flex align-items--center  justify-content--start gap--sm"
                      style={{ textTransform: 'capitalize' }}
                    >
                      <div className="d--flex flex--column">
                        <div>
                          {getNumberName(element?.destination_number, false)}
                          {element?.destination_number}
                        </div>
                        {element?.destination_number?.length < 5 && (
                          <div className="font--xs">{`Ext. ${element?.destination_number}`}</div>
                        )}
                        {element?.destination_number?.length > 5 &&
                          !getNumberName(
                            element?.destination_number,
                            false,
                          )?.includes('+') && (
                            <div className="font--xs">{`Ph. ${formatPhoneNumber(
                              element?.destination_number,
                            )}`}</div>
                          )}
                      </div>
                    </span>
                  </>
                ) : (
                  <span className="d--flex">
                    {element?.forward_type === 'EXTENSION' ? (
                      <>
                        <div className="d--flex flex--column">
                          <div>
                            {getNumberName(element?.forward_value, false)}
                          </div>
                          {element?.forward_value?.length < 5 && (
                            <div className="font--xs">{`Ext. ${element?.forward_value}`}</div>
                          )}
                            {element?.forward_value?.length > 5 &&
                            !getNumberName(
                              element?.forward_value,
                              false,
                            )?.includes('+') && (
                              <div className="font--xs">{`Ph. ${formatPhoneNumber(
                                element?.forward_value,
                              )}`}</div>
                            )}
                        </div>
                      </>
                    ) : element?.forward_type === 'DEPARTMENT' ||
                      element?.forward_type === 'VOICEMAILGROUP' ? (
                      <CustomToolTip
                        id={props?.row?.id}
                        options={members}
                        accessorKey="name"
                        separatorComponent={SeparatorItem}
                        controlled={true}
                        enableTooltip={members?.length > 0}
                      >
                        <span className="d--flex align-items--center gap--xs">
                          <UsersIcon />
                          <span>{departmentName}</span>
                        </span>
                      </CustomToolTip>
                    ) : element?.forward_type === 'IVR' ? (
                      <span className="d--flex align-items--center gap--xs">
                        <MobileIcon />
                        <span>{ivrName}</span>
                      </span>
                    ) : (
                      <>
                        {element?.forward_type}
                        {handleMembers(element?.members)}
                      </>
                    )}
                  </span>
                )}
              </>
            )}
          </>
        );
      },
      meta: {
        width: '20%',
      },
    },
    {
      accessorKey: 'status',
      header: () => 'Status',
      cell: (props) => {
        return <>{getStatusItem(props?.getValue())}</>;
      },
      meta: {
        width: '5%',
      },
    },
    {
      accessorKey: 'duration',
      header: () => 'Duration',
      cell: (props) => durationToMMSS(props.getValue()),
      meta: {
        textAlign: 'center',
        width: '5%',
      },
    },
    {
      accessorKey: 'charge',
      header: () => 'Charge',
      meta: {
        textAlign: 'center',
        width: '5%',
      },
    },
    {
      accessorKey: 'action',
      header: () => 'Action',
      cell: (props) => {
        const element = props?.row?.original;
        const { recording_file = '', is_voicemail, direction = '' } = element;
        const isPlaying =
          playingData?.playing && playingData?.file === recording_file;
        return (
          <div className="gap--xs w--full d--flex align-items--center justify-content--center">
            {recording_file && (
              <>
                {isPlaying ? (
                  <span
                    className="c--pointer"
                    onClick={() => {
                      setPlayingData({
                        playing: false,
                        file: null,
                        user_id: '',
                      });
                    }}
                  >
                    <HoldIcon width={20} height={20} />
                  </span>
                ) : (
                  <span
                    className={`c--pointer ${
                      element?.seen_at ? 'text--success' : 'text--red'
                    } `}
                    onClick={() => {
                      // if (!element?.seen_at) {
                      //   callRecordingMutateSeen({
                      //     uuid: element?.xml_cdr_uuid,
                      //   });
                      // }
                      setPlayingData({
                        playing: true,
                        file: recording_file,
                        user_id: element?.user?.uuid,
                      });
                    }}
                  >
                    <span
                      data-tooltip={
                        is_voicemail === 1 ? 'Voicemail' : 'Recording'
                      }
                      tooltip-position="left"
                    >
                      <PlayIcon width={20} height={20} />
                    </span>
                  </span>
                )}
              </>
            )}
          </div>
        );
      },
      meta: {
        colType: 'action',
        textAlign: 'center',
        width: '5%',
      },
    },
  ];
  return (
    <Fragment>
      <div className="w--full tab">
        <TableManager
          {...{
            name: 'Voicemails',
            fetcherKey: 'getCalls',
            fetcherFn: getCalls,
            columns,

            shouldFilter: true,
            initialFilterState: filterInitialValues,
            shouldShowTotalCount: true,
            customFilters: true,
            enableDefaultFilter: 'Show All',
            extraParams: {
              type: 'ALL',
              request_type: 'voicemail-list',
              uuid: selected_user_id,
            },
            customDisableForCall: true,
            showMoreOption: (data) => {
              return data?.count > 1;
            },
            enableMoreOption: true,
            moreDataFunction: getParticularCallHistory,
            moreDataFnPayload: (data, uuid) => {
              return {
                uuid: selected_user_id,
                xml_cdr_uuid: data?.xml_cdr_uuid,
              };
            },
          }}
        />
        {playingData?.playing && (
          <AudioPlayer
            onClose={() => {
              setPlayingData({ playing: false, file: null, user_id: '' });
            }}
            name={playingData?.file}
            url={`signed-url/${playingData?.user_id}/recording?filename=${playingData?.file}`}
          />
        )}
      </div>
    </Fragment>
  );
};

export default Voicemail;
