import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { getAgentPlanDetails } from '../../../../../api';

const PlanDetail = () => {
  const { id = '' } = useParams();
  const { data } = useQuery({
    queryKey: ['get-agent-plan-details'],
    queryFn: () => getAgentPlanDetails({ uuid: id }),
    select: (data) => data?.data?.data,
  });

  const { plan = {}, plan_detail = {} } = data || {};

  return (
    <div className="w--full box-shadow--xs  ">
      <div className="w--full d--flex gap--md flex--column">
        <div className="label--control font--sm font--500 m-b--xs h-min--36  d--flex gap--sm align-items--center text--primary bg--primary-100 border-full--primary-100 p-t--sm p-b--sm p-r--md p-l--md radius--sm ">
          Plan Details
        </div>
        <div className=" border-full--black-100  d--flex flex--column bg--white  p-r--md  p-t--sm p-b--sm p-l--md radius--sm">
          <div className="d--flex w--full justify-content--between align-items--center border-bottom--black-50 h-min--50 ">
            <div className="font--sm font--600">Plan</div>
            <div className="font--sm d--flex align-items--center white--space-none gap--sm">
              {plan?.plan_name}
            </div>
          </div>
          <div className="d--flex w--full justify-content--between align-items--center border-bottom--black-50 h-min--50 ">
            <div className="font--sm font--600">Plan Cost</div>
            <div className="font--sm ">${plan.yearly_cost}/yr</div>
          </div>
          <div className="d--flex w--full justify-content--between align-items--center h-min--50 ">
            <div className="font--sm font--600">Plan Renewal Date</div>
            <div className="font--sm ">{data?.plan_expiration_date}</div>
          </div>
        </div>
        <div className="label--control font--sm font--500 m-b--xs h-min--36  d--flex gap--sm align-items--center text--primary bg--primary-100 border-full--primary-100 p-t--sm p-b--sm p-r--md p-l--md radius--sm ">
          Your Plan Includes Following
        </div>
        <div className="d--flex gap--md w--full">
          <div className="border-full--black-100  d--flex  flex--column bg--white p-r--md  p-t--sm p-b--sm p-l--md radius--sm w--full">
            <div className="d--flex w--full justify-content--between align-items--center border-bottom--black-100  h-min--50 ">
              <div className="font--sm font--600">
                Included Free Calling Minutes
              </div>
              <div className="font--sm ">
                (used) {plan?.free_calls - data?.outgoing_minutes ?? 0}/
                {plan?.free_calls}
              </div>
            </div>
            <div className="d--flex w--full justify-content--between align-items--center border-bottom--black-100  h-min--50 ">
              <div className="font--sm font--600">Included Free SMS</div>
              <div className="font--sm ">
                (used) {plan?.free_sms - data?.free_sms_used ?? 0}/
                {plan?.free_sms}
              </div>
            </div>
            <div className="d--flex w--full justify-content--between align-items--center   h-min--50 ">
              <div className="font--sm font--600">Included Virtual Number</div>
              <div className="font--sm ">
                (used) {plan_detail?.free_did_used ?? 0}/{plan_detail?.free_did}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanDetail;
