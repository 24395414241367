import React from 'react';
import TableManager from '../../../../components/TableManager';
import { getAgentBillingDetails } from '../../../../api';
import { useParams } from 'react-router-dom';
import { formatDate } from '../../../../helpers/functions';

const PlanBilling = () => {
  const { id } = useParams();
  const columns = [
    {
      accessorKey: 'created_at',
      header: () => 'Date',
      cell: (props) => formatDate(props.getValue()),
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'billno',
      header: () => 'Transaction No',
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'plan',
      header: () => 'Plan Name',
      cell: (props) => props?.row?.original?.plan?.plan_name,
    },
    {
      accessorKey: 'mode',
      header: () => 'Mode',
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'total_amount',
      header: () => 'Amount',
      meta: {
        textAlign: 'right',
      },
    },
    {
      accessorKey: 'status',
      header: () => 'Status',
      meta: {
        textAlign: 'center',
      },
    },
  ];
  return (
    <div className="w--full userDetailsCommon">
      <TableManager
        {...{
          name: 'Plan Billing',
          columns,
          fetcherFn: getAgentBillingDetails,
          fetcherKey: 'getAgentBillingDetailsPlan',
          extraClasses: 'table--responsive--full',
          extraParams: {
            uuid: id,
            type: 'plan',
          },
          shouldShowTotalCount: true,
        }}
      />
    </div>
  );
};

export default PlanBilling;
