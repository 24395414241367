import React from 'react';
import { useParams } from 'react-router-dom';
import { formatDate } from '../../../../helpers/functions';
import TableManager from '../../../../components/TableManager';
import MultiDropDown from '../../../../components/MultiDropdown';
import AgencyMemberList from './AgencyMemberList';
import { getAgentAgencies } from '../../../../api';

const Agency = () => {
  const { id } = useParams();

  const columns = [
    {
      accessorKey: 'created_at',
      header: () => 'Date',
      cell: (props) => formatDate(props.getValue()),
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'agency_name',
      header: () => 'Name',
      cell: (props) => props.getValue(),
    },
    {
      accessorKey: 'agency_owner_name',
      header: () => 'Owner Name',
      cell: (props) => {
        return (
          <span className="d--flex gap--sm  align-items--center">
            {props.getValue()}
          </span>
        );
      },
    },
    {
      accessorKey: 'agency_member_count',
      header: () => 'Employees',
      cell: (props) => {
        const element = props?.row?.original;
        return (
          <AgencyMemberList agency_uuid={element?.uuid}>
            <div className="w--full d--flex justify-content--center ">
              <div
                className={`text--primary radius--full  bg--primary-100 w-max--28 w-min--28  h-max--28 h-min--28 d--flex align-items--center justify-content--center c--pointer`}
                data-link-hover
              >
                {props.getValue()}
              </div>
            </div>
          </AgencyMemberList>
        );
      },
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'email',
      header: () => 'Email',
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'assigned_did',
      header: () => 'Virtual Number',
      cell: (props) => {
        const element = props?.row?.original || {};
        const { assigned_did = [] } = element;
        const filteredAssigned = assigned_did?.map((item) => item?.did_number);
        return (
          <>
            {assigned_did?.length === 0 ? (
              '---'
            ) : (
              <div className="w-max--120">
                <MultiDropDown
                  labelKey={'did_number'}
                  valueKey={'did_number'}
                  value={filteredAssigned ?? []}
                  placeholder={`${assigned_did?.length} number(s)`}
                  readOnly={true}
                  optionList={assigned_did || []}
                  withCaret={true}
                  height="28"
                  paddingRight="xs"
                  paddingLeft="xs"
                  extraClasses="no--focus link"
                  isSearchable={false}
                />
              </div>
            )}
          </>
        );
      },
    },
    {
      accessorKey: 'status',
      header: () => 'Status',
      cell: (props) => {
        if (props?.getValue() === 1) {
          return (
            <div
              data-tooltip={'Activated'}
              tooltip-position="top"
              className="bg--success radius--full p-l--sm  p-r--sm w-min--20 h-min--20 w-max--20 h-max--20  text--white d--flex justify-content--center align-items--center"
            >
              A
            </div>
          );
        } else {
          return (
            <div
              data-tooltip={'Deactivated'}
              tooltip-position="top"
              className="bg--secondary radius--full p-l--sm p-r--sm w-min--20 h-min--20 w-max--20 h-max--20 text--white d--flex justify-content--center align-items--center"
            >
              D
            </div>
          );
        }
      },
      meta: {
        width: '5%',
        textAlign: 'left',
      },
    },
  ];

  return (
    <>
      <div className="w--full h--full userDetailsCommon">
        <TableManager
          {...{
            name: 'Agencies',
            columns,
            fetcherFn: getAgentAgencies,
            fetcherKey: 'getAgentAgencies',
            extraParams: {
              uuid: id,
            },
            shouldShowTotalCount: true,
          }}
        />
      </div>
    </>
  );
};

export default Agency;
