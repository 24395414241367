import React, { useEffect, useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import Modal from '../Modal';

const AudioPlayer = ({
  name = '',
  url = '',
  onClose = () => null,
  type = 'recording',
}) => {
  const { user } = useAuth();
  const [mediaUrl, setMediaUrl] = useState('');
  useEffect(() => {
    if (name) {
      if (url) {
        setMediaUrl(`${process.env.REACT_APP_MEDIA_URL}${url}`);
      } else {
        setMediaUrl(
          `${process.env.REACT_APP_MEDIA_URL}${
            user?.parent_uuid || user?.uuid
          }/${type}?filename=${name}`,
        );
      }
    }
  }, [name]);

  return (
    <Modal
      headerComponent={null}
      footerComponent={null}
      handleClose={() => onClose()}
      shouldCloseOnClickOutside={true}
      height="75"
      radius="full"
    >
      <div className="w--full h--full h-min--60 d--flex align-items--center justify-content--center">
        <audio
          autoPlay
          src={mediaUrl}
          controls
          className="w--full"
          controlsList="nodownload noplaybackrate"
        ></audio>
      </div>
    </Modal>
  );
};

export default AudioPlayer;
