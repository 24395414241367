import React from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const dataPoints = [
  { label: 'Jan', total: 0, did: 0, plan: 0 },
  { label: 'Feb', total: 0, did: 0, plan: 0 },
  { label: 'Mar', total: 0, did: 0, plan: 0 },
  { label: 'Apr', total: 0, did: 0, plan: 0 },
  { label: 'May', total: 0, did: 0, plan: 0 },
  { label: 'Jun', total: 0, did: 0, plan: 0 },
  { label: 'Jul', total: 0, did: 0, plan: 0 },
  { label: 'Aug', total: 0, did: 0, plan: 0 },
  { label: 'Sep', total: 0, did: 0, plan: 0 },
  { label: 'Oct', total: 0, did: 0, plan: 0 },
  { label: 'Nov', total: 0, did: 0, plan: 0 },
  { label: 'Dec', total: 0, did: 0, plan: 0 },
];

const BillingGraph = ({ graphData }) => {
  const options = {
    animationEnabled: true,
    title: {
      text: graphData?.title ?? 'Yearly Billing Summary ',
      fontSize: 35,
      fontWeight: 'semibold',
    },
    toolTip: {
      shared: true,
    },
    axisY: {
      gridColor: 'lightgray',
      gridThickness: 1,
      labelFormatter: function (e) {
        return '$' + e?.value;
      },
    },
    data: [
      {
        type: 'line',
        name: 'DID',
        showInLegend: true,
        dataPoints: (graphData?.data ?? dataPoints).map((point) => ({
          label: point.label,
          y: parseFloat(point.did),
          yValueFormatString: '$#,##0',
        })),
      },
      {
        type: 'line',
        name: 'Plan',
        showInLegend: false,
        dataPoints: (graphData?.data ?? dataPoints).map((point) => ({
          label: point.label,
          y: parseFloat(point.plan),
          yValueFormatString: '$#,##0',
        })),
        color: 'green',
      },
      // {
      //   type: 'line',
      //   name: 'Top Up',
      //   showInLegend: true,
      //   dataPoints: (graphData?.data ?? dataPoints).map((point) => ({
      //     label: point.label,
      //     y: parseFloat(point.top_up),
      //     yValueFormatString: '$#,##0',
      //   })),
      //   color: '#D71313',
      // },
    ],
  };

  return <CanvasJSChart options={options} />;
};

export default BillingGraph;
