import React, { useEffect } from 'react';
import Button from '../../../components/Button';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormSelect from '../../../components/FormSelect';
import {
  addPlanValidationSchema,
  initialValues,
  radioTypes,
} from '../constants';
import FormInput from '../../../components/FormInput';
import MultiDropDown from '../../../components/MultiDropdown';
import { Country } from 'country-state-city';
import useRateCardListing from '../../../hooks/useRateCardListing';
import useProviderListing from '../../../hooks/useProviderListing';
import useAddPlan from '../../../hooks/useAddPlan';
import useUpdatePlan from '../../../hooks/useUpdatePlan';
const countriesList = Country.getAllCountries()
  .filter((item) => ['US'].includes(item.isoCode))
  .map((country) => {
    return { label: country.name, value: country.phonecode.replace('+', '') };
  });

const renderCustomPlanOption = ({ item }) => {
  return (
    <option key={item?.value} value={item?.value}>
      {item?.label}
    </option>
  );
};
const renderRateCardOption = ({ item }) => {
  return (
    <option key={item?.uuid} value={item?.uuid}>
      {item?.name}
    </option>
  );
};

const AddPlan = ({
  setOpenSlider,
  rowData,
  handleClose = () => null,
  isView = false,
}) => {
  const isEdit = rowData && Object.keys(rowData).length > 0;

  const { mutateAsync: rateCardMutate, data: rateCardData } =
    useRateCardListing();
  const { mutateAsync: providerMutate, data: providerData } =
    useProviderListing();
  const { mutateAsync: addPlanMutate, isLoading: isLoadinguseAddPlan } =
    useAddPlan();
  const { mutateAsync: updatePlanMutate, isLoading: isLoadinguseUpdatePlan } =
    useUpdatePlan();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(addPlanValidationSchema),
    defaultValues: initialValues,
    mode: 'onSubmit',
  });

  useEffect(() => {
    rateCardMutate({ type: 'Sell' });
    providerMutate();
  }, []);

  useEffect(() => {
    if (rateCardData?.data?.data?.length > 0) {
      setValue('inbound_ratecard_uuid', rateCardData?.data?.data?.[0]?.uuid);
      setValue('outbound_ratecard_uuid', rateCardData?.data?.data?.[0]?.uuid);
    }
    if (providerData?.data?.data?.length > 0) {
      setValue('call_carriers_uuid', [providerData?.data?.data?.[0]?.uuid]);
    }
  }, [
    rateCardData?.data?.data?.length,
    providerData?.data?.data?.length,
    providerData?.data?.data?.length,
  ]);

  useEffect(() => {
    if (isEdit) {
      let {
        plan_name = '',
        service_type = '',
        custom_plan = '',
        number_of_dids = '',
        plan_countries = [],
        free_countries = [],
        free_calls = '',
        free_sms = '',
        number_of_member = '',
        per_member_addon_amt = '',
        cloud_space = '',
        extra_cloud_space_cost = '',
        group = '',
        ivr = '',
        free_trial = '',
        monthly_cost = '',
        quarterly_cost = '',
        did_cost,
        yearly_cost = '',
        inbound_ratecard_uuid = '',
        outbound_ratecard_uuid = '',
        call_carriers_uuid = [],
      } = rowData;
      reset({
        plan_name,
        service_type,
        custom_plan,
        did: number_of_dids,
        plan_countries: plan_countries || [],
        free_countries: free_countries || [],
        free_calls,
        free_sms,
        group,
        ivr,
        members: number_of_member,
        member_cost: per_member_addon_amt,
        storage: cloud_space,
        storage_cost: extra_cloud_space_cost,
        free_trial,
        monthly_cost,
        quarterly_cost,
        yearly_cost,
        did_cost,
        inbound_ratecard_uuid,
        outbound_ratecard_uuid,
        call_carriers_uuid: call_carriers_uuid?.map(({ key }) => key),
      });
    }
  }, [isEdit]);

  const handleSubmitForm = (data) => {
    let {
      plan_name,
      did,
      plan_countries,
      service_type,
      free_countries,
      free_calls,
      free_sms,
      members,
      member_cost,
      group,
      ivr,
      storage,
      did_cost,
      free_trial,
      monthly_cost,
      quarterly_cost,
      yearly_cost,
      inbound_ratecard_uuid,
      outbound_ratecard_uuid,
      call_carriers_uuid = [],
    } = data;
    const payload = {
      plan_name,
      service_type,

      plan_countries,
      free_countries,
      free_calls,
      number_of_member: members,
      per_member_addon_amt: member_cost,
      free_sms,
      group,
      ivr,

      number_of_dids: did,
      did_cost,
      // cloud_space: storage,
      // extra_cloud_space_cost: did_cost,
      // free_trial,
      monthly_cost,
      // quarterly_cost,
      yearly_cost,

      inbound_ratecard_uuid: inbound_ratecard_uuid,
      outbound_ratecard_uuid: outbound_ratecard_uuid,
      call_carriers_uuid:
        call_carriers_uuid?.map((id) => {
          const data = providerData?.data?.data?.find(
            ({ uuid = '' }) => id === uuid,
          );
          if (data) {
            return {
              key: id,
              name: data?.name,
            };
          }
          return;
        }) || [],
    };

    if (rowData?.uuid) {
      updatePlanMutate({ ...payload, uuid: rowData?.uuid }).then(() => {
        handleClose();
      });
    } else {
      addPlanMutate(payload).then(() => {
        handleClose();
      });
    }
  };

  const isLoad = isLoadinguseAddPlan || isLoadinguseUpdatePlan;

  return (
    <form
      className="w--full  d--flex flex--column gap--md"
      onSubmit={handleSubmit(handleSubmitForm)}
      autoComplete="off"
    >
      <div className="overflow--auto dialogScroll w--full d--flex flex--column gap--md p--md">
        <div className="d--flex gap--md w-100  mx-1">
          <Controller
            name="plan_name"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="input"
                placeholder="Enter Plan"
                label="Plan Name"
                error={errors?.plan_name?.message}
                maxLength={25}
                disabled={isView}
              />
            )}
          />
          {/* <Controller
            name="custom_plan"
            control={control}
            render={({ field }) => (
              <FormSelect
                {...field}
                label="Custom Plan"
                renderOption={renderCustomPlanOption}
                error={errors?.custom_plan?.message}
                options={radioTypes}
                disabled={isView}
              />
            )}
          /> */}
        </div>

        <div className="d--flex flex--column">
          <h5 className="font--sm font--600 h-min--36 p-l--sm p-r--sm bg--primary-100 radius--sm d--flex align-items--center m-b--md">
            PBX
          </h5>
          <div className="d--flex gap--md align-items--center">
            <Controller
              name="plan_countries"
              control={control}
              render={({ field }) => (
                <MultiDropDown
                  {...field}
                  labelKey={'label'}
                  valueKey={'value'}
                  label="Plan Country"
                  className="p-0"
                  optionList={countriesList}
                  readOnly={isView}
                  error={errors?.plan_countries?.message}
                />
              )}
            />
            <Controller
              name="free_countries"
              control={control}
              render={({ field }) => (
                <MultiDropDown
                  {...field}
                  labelKey={'label'}
                  valueKey={'value'}
                  label="Allow countries for free minutes"
                  optionList={countriesList}
                  placeHolder={'Select Countries'}
                  readOnly={isView}
                  error={errors?.free_countries?.message}
                />
              )}
            />
            <Controller
              name="free_calls"
              type="number"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  type="number"
                  label="Free Minutes for call "
                  error={errors?.free_calls?.message}
                  disabled={isView}
                />
              )}
            />
          </div>
          <div className="d--flex gap--md align-items--center">
            <Controller
              name="members"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  type="number"
                  label="No of Agents"
                  error={errors?.members?.message}
                  disabled={isView}
                />
              )}
            />
            <Controller
              name="member_cost"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  type="number"
                  placeholder="Enter cost"
                  label="Per agent cost"
                  error={errors?.member_cost?.message}
                  disabled={isView}
                />
              )}
            />
          </div>
          <div className="d--flex gap--md align-items--center">
            <Controller
              name="free_sms"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  type="number"
                  label="Free SMS"
                  error={errors?.free_sms?.message}
                  min={0}
                  disabled={isView}
                />
              )}
            />
            <Controller
              name="group"
              control={control}
              render={({ field }) => (
                <FormSelect
                  {...field}
                  label="Department"
                  error={errors?.group?.message}
                  renderOption={renderCustomPlanOption}
                  options={radioTypes}
                  disabled={isView}
                />
              )}
            />
            <Controller
              name="ivr"
              control={control}
              render={({ field }) => (
                <FormSelect
                  {...field}
                  label="IVR"
                  error={errors?.ivr?.message}
                  renderOption={renderCustomPlanOption}
                  options={radioTypes}
                  disabled={isView}
                />
              )}
            />
          </div>
        </div>
        <div className="d--flex flex--column">
          <h5 className="font--sm font--600 h-min--36 p-l--sm p-r--sm bg--primary-100 radius--sm d--flex align-items--center m-b--md">
            DID
          </h5>
          <div className="d--flex gap--md align-items--center ">
            <Controller
              name="did"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  type="number"
                  label="No of Free DID"
                  error={errors?.did?.message}
                  disabled={isView}
                />
              )}
            />
            <div className="w--full">
              <div
                className={`label--control font--sm font--500 m-b--xs  w--full ${
                  errors?.did_cost?.message ? 'text--danger' : 'text--black'
                }`}
              >
                {errors?.did_cost?.message
                  ? errors?.did_cost?.message
                  : 'DID Cost'}
              </div>
              <div className="d--flex align-items--center input--group">
                <Controller
                  name="did_cost"
                  control={control}
                  render={({ field }) => (
                    <>
                      <span className="bg--primary-100 border-full--primary-100 p-l--md p-r--md h--full h-min--36 d--flex align-items--center justify-content--center r-t-left--sm r-b-left--sm white--space-none">
                        $
                      </span>
                      <FormInput
                        {...field}
                        type="number"
                        placeHolder={'Cost'}
                        min={0}
                        radiusTopRight={0}
                        radiusBottomRight={0}
                        disabled={isView}
                      />
                      <span className="bg--primary-100 border-full--primary-100 p-l--md p-r--md h--full h-min--36 d--flex align-items--center justify-content--center r-t-right--sm r-b-right--sm">
                        /Month
                      </span>
                    </>
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="d--flex flex--column">
          <h5 className="font--sm font--600 h-min--36 p-l--sm p-r--sm bg--primary-100 radius--sm d--flex align-items--center m-b--md">
            Plan Cost
          </h5>
          <div className="d--flex gap--md align-items--center">
            {/* <div className="w--full">
              <div className="label--control font--sm font--500 m-b--xs  text--black w--full">
                Free Trial
              </div>
              <div className="d--flex align-items--center input--group">
                <Controller
                  name="free_trial"
                  control={control}
                  render={({ field }) => (
                    <>
                      <FormInput
                        {...field}
                        type="number"
                        label=""
                        error={errors?.free_trial?.message}
                        min={0}
                        max={20}
                        radiusTopRight={0}
                        radiusBottomRight={0}
                        disabled={isView}
                      />
                      <span className="bg--primary-100 border-full--primary-100 p-l--md p-r--md h--full h-min--36 d--flex align-items--center justify-content--center r-t-right--sm r-b-right--sm white--space-none">
                        / Days
                      </span>
                    </>
                  )}
                />
              </div>
            </div> */}
            <div className="w--full">
              <div
                className={`label--control font--sm font--500 m-b--xs  w--full ${
                  errors?.monthly_cost?.message ? 'text--danger' : 'text--black'
                }`}
              >
                {errors?.monthly_cost?.message
                  ? errors?.monthly_cost?.message
                  : 'Monthly Cost'}
              </div>

              <div className="d--flex align-items--center input--group">
                <Controller
                  name="monthly_cost"
                  control={control}
                  render={({ field }) => (
                    <>
                      <span className="bg--primary-100 border-full--primary-100 p-l--md p-r--md h--full h-min--36 d--flex align-items--center justify-content--center r-t-left--sm r-b-left--sm white--space-none">
                        $
                      </span>
                      <FormInput
                        {...field}
                        type="number"
                        label=""
                        min={0}
                        step="0.01"
                        radiusTopLeft={0}
                        radiusBottomLeft={0}
                        disabled={isView}
                      />
                    </>
                  )}
                />
              </div>
            </div>
            {/* <div className="w--full">
              <div className="label--control font--sm font--500 m-b--xs  text--black w--full">
                Quarterly Cost
              </div>
              <div className="d--flex align-items--center input--group">
                <Controller
                  name="quarterly_cost"
                  control={control}
                  render={({ field }) => (
                    <>
                      <span className="bg--primary-100 border-full--primary-100 p-l--md p-r--md h--full h-min--36 d--flex align-items--center justify-content--center r-t-left--sm r-b-left--sm white--space-none">
                        $
                      </span>
                      <FormInput
                        {...field}
                        type="number"
                        label=""
                        error={errors?.quarterly_cost?.message}
                        min={0}
                        step="0.01"
                        radiusTopLeft={0}
                        radiusBottomLeft={0}
                        disabled={isView}
                      />
                    </>
                  )}
                />
              </div>
            </div> */}
            <div className="w--full">
              <div
                className={`label--control font--sm font--500 m-b--xs  w--full ${
                  errors?.yearly_cost?.message ? 'text--danger' : 'text--black'
                }`}
              >
                {errors?.yearly_cost?.message
                  ? errors?.yearly_cost?.message
                  : 'Yearly Cost'}
              </div>
              <div className="d--flex align-items--center input--group">
                <Controller
                  name="yearly_cost"
                  control={control}
                  render={({ field }) => (
                    <>
                      <span className="bg--primary-100 border-full--primary-100 p-l--md p-r--md h--full h-min--36 d--flex align-items--center justify-content--center r-t-left--sm r-b-left--sm white--space-none">
                        $
                      </span>
                      <FormInput
                        {...field}
                        type="number"
                        label=""
                        min={0}
                        step="0.01"
                        radiusTopLeft={0}
                        radiusBottomLeft={0}
                        disabled={isView}
                      />
                    </>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="d--flex flex--column">
          <h5 className="font--sm font--600 h-min--36 p-l--sm p-r--sm bg--primary-100 radius--sm d--flex align-items--center m-b--md">
            Rate Card
          </h5>
          <div className="d--flex gap--md align-items--center">
            <Controller
              name="inbound_ratecard_uuid"
              control={control}
              render={({ field }) => (
                <>
                  <FormSelect
                    {...field}
                    label="Inbound Rate Card"
                    error={errors?.inbound_ratecard_uuid?.message}
                    renderOption={renderRateCardOption}
                    options={rateCardData?.data?.data || []}
                    disabled={isView}
                    defaultOption={false}
                  />
                </>
              )}
            />
            <Controller
              name="outbound_ratecard_uuid"
              control={control}
              render={({ field }) => (
                <>
                  <FormSelect
                    {...field}
                    label="Outbound Rate Card"
                    error={errors?.outbound_ratecard_uuid?.message}
                    options={rateCardData?.data?.data || []}
                    renderOption={renderRateCardOption}
                    disabled={isView}
                    defaultOption={false}
                  />
                </>
              )}
            />
            <Controller
              name="call_carriers_uuid"
              control={control}
              render={({ field }) => (
                <>
                  <MultiDropDown
                    {...field}
                    labelKey={'name'}
                    valueKey={'uuid'}
                    label="Call Carrier"
                    optionList={providerData?.data?.data || []}
                    error={errors?.call_carriers_uuid?.message}
                    readOnly={isView}
                  />
                </>
              )}
            />
          </div>
        </div>
      </div>
      {!isView && (
        <div className="d--flex justify-content--between gap--md p-r--md p-l--md">
          <Button
            type="button"
            variant="contrast"
            color="primary"
            btnClasses="btn"
            onClick={() => setOpenSlider(false)}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            size=""
            variant="primary"
            color="white"
            btnClasses="btn"
            disabled={isLoad}
          >
            {isLoad ? 'Please wait...' : 'Save'}
            {/* {isLoading ? 'Please wait...' : uuid ? 'Update' : 'Save'} */}
          </Button>
        </div>
      )}
    </form>
  );
};

export default AddPlan;
