import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormInput from '../../../components/FormInput';
import FormSelect from '../../../components/FormSelect';

const initialValues = {
  changes_in: '1',
};
export const validationSchema = yup.object({
  did_numbers: yup
    .array()
    .min(1, 'Please select at least one code')
    .typeError('Please select atleast one carrier')
    .nullable(),
});
const countries = [];

const renderCountryItem = ({ item }) => {
  return (
    <option key={item?.uuid} value={item?.uuid}>
      {item?.name}
    </option>
  );
};

const UpdateCallRates = ({ setOpenSlider }) => {
  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onSubmit',
  });
  const watchChangesIn = watch('changes_in');
  const handleSubmitForm = (data) => {
    console.log(
      '🚀 ~ file: UpdateCallRates.js:36 ~ handleSubmitForm ~ data:',
      data,
    );
  };
  return (
    <form onSubmit={handleSubmit(handleSubmitForm)} >
      <div className='overflow--auto dialogScroll w--full d--flex flex--column gap--md p--md'>

        <div className="SliderFormBody">
          <div className="d-flex justify-content-between w-100 mx-1 mt-2">
            <label className="form-label">Changes In</label>
            <span className="radio-group-sp">
              <div className="form-check form-check-inline">
                <input
                  {...register('changes_in')}
                  name="changes_in"
                  className="form-check-input"
                  type="radio"
                  id="sip_registraton_12"
                  value="1"
                  checked={watchChangesIn === '1'}
                />
                <label className="form-label" htmlFor="sip_registraton_12">
                  All Countries
                </label>
              </div>
              <div className=" form-check form-check-inline">
                <input
                  {...register('changes_in')}
                  name="changes_in"
                  className="form-check-input"
                  type="radio"
                  id="sip_registraton_13"
                  value="0"
                  checked={watchChangesIn === '0'}
                />
                <label className="form-label" htmlFor="sip_registraton_13">
                  Select Country
                </label>
              </div>
            </span>
          </div>
          {watchChangesIn === '0' && (
            <div className="w-100 d-flex gutter--1 mt-2">
              <div className="w-100 mx-1">
                <Controller
                  control={control}
                  name="country"
                  render={({ field }) => (
                    <FormSelect
                      {...field}
                      options={countries || []}
                      renderOption={renderCountryItem}
                      placeholder="Select country"
                      label="Country"
                      error={errors.country?.message}
                    />
                  )}
                />
              </div>
              <div className="w-100 mx-1"></div>
            </div>
          )}
          <div className="w-100 d-flex gutter--1 mt-2">
            <div className="w-100 mx-1">
              <label htmlFor="free_trial" className="form-label">
                Increase Margin
              </label>
              <div className="d-flex">
                <div className="input-group mb-3">
                  <input
                    {...register('free_trial')}
                    type="number"
                    className={
                      errors?.free_trial?.message
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    id="free_trial"
                    min={0}
                  />
                  <span
                    className="input-group-text p-1 px-2"
                    style={{
                      borderTopRightRadius: '5px',
                      borderBottomRightRadius: '5px',
                    }}
                  >
                    %
                  </span>
                  <div className="invalid-feedback">
                    {errors?.free_trial?.message}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 mx-1">
              <label htmlFor="free_trial" className="form-label">
                Decrease Margin
              </label>
              <div className="d-flex">
                <div className="input-group mb-3">
                  <input
                    {...register('free_trial')}
                    type="number"
                    className={
                      errors?.free_trial?.message
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    id="free_trial"
                    min={0}
                  />
                  <span
                    className="input-group-text p-1 px-2"
                    style={{
                      borderTopRightRadius: '5px',
                      borderBottomRightRadius: '5px',
                    }}
                  >
                    %
                  </span>
                  <div className="invalid-feedback">
                    {errors?.free_trial?.message}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end p-r--md p-l--md">
        <button
          type="button"
          className="btn btn-grey me-2"
          onClick={() => setOpenSlider(null)}
        >
          Close
        </button>
        <button className="btn btn--primary " type="submit">
          Save
        </button>
      </div>
    </form>
  );
};

export default UpdateCallRates;
