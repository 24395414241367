import React from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const dataPoints = [
  { label: 'Jan', total: 0, new: 0 },
  { label: 'Feb', total: 0, new: 0 },
  { label: 'Mar', total: 0, new: 0 },
  { label: 'Apr', total: 0, new: 0 },
  { label: 'May', total: 0, new: 0 },
  { label: 'Jun', total: 0, new: 0 },
  { label: 'Jul', total: 0, new: 0 },
  { label: 'Aug', total: 0, new: 0 },
  { label: 'Sep', total: 0, new: 0 },
  { label: 'Oct', total: 0, new: 0 },
  { label: 'Nov', total: 0, new: 0 },
  { label: 'Dec', total: 0, new: 0 },
];

const UserGraph = ({ graphData }) => {
  const options = {
    animationEnabled: true,
    title: {
      text: graphData?.title ?? 'Yearly Users Summary',
      fontSize: 35,
      fontWeight: 'semibold',
    },
    toolTip: {
      shared: true,
    },
    axisY: {
      gridColor: 'lightgray',
      gridThickness: 1,
    },
    data: [
      {
        type: 'column',
        name: 'Total Tenants',
        showInLegend: true,
        dataPoints: (graphData?.data ?? dataPoints).map((point) => ({
          label: point.label,
          y: point.total,
        })),
        color: '#007193',
      },
      {
        type: 'line',
        name: 'New Tenants',
        showInLegend: true,
        dataPoints: (graphData?.data ?? dataPoints).map((point) => ({
          label: point.label,
          y: point.new,
        })),
        color: '#ff4e43',
      },
      // {
      //   type: 'line',
      //   name: 'Inactive Users',
      //   showInLegend: true,
      //   dataPoints: (graphData?.data ?? dataPoints).map((point) => ({
      //     label: point.label,
      //     y: point.inactive,
      //   })),
      //   color: '#EC53B0',
      // },
      // {
      //   type: 'line',
      //   name: 'Suspended Users',
      //   showInLegend: true,
      //   dataPoints: (graphData?.data ?? dataPoints).map((point) => ({
      //     label: point.label,
      //     y: point.suspended,
      //   })),
      //   color: '#FFC436',
      // },
      // {
      //   type: 'line',
      //   name: 'Expired Users',
      //   showInLegend: true,
      //   dataPoints: (graphData?.data ?? dataPoints).map((point) => ({
      //     label: point.label,
      //     y: point.expired,
      //   })),
      //   color: '#D71313',
      // },
    ],
  };

  return <CanvasJSChart options={options} />;
};

export default UserGraph;
