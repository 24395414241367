import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { formatPhoneNumber } from '../../helpers/functions';
import { getAllNumberDetails } from '../../api';

export default function useAllNumberDetails({ uuid = '' }) {
  const { data: numberList = [], ...rest } = useQuery({
    queryFn: () => getAllNumberDetails({ uuid }),
    queryKey: ['getAllNumberDetails', uuid],
    refetchOnWindowFocus: false,
    retry: false,
    gcTime: Infinity,
    refetchOnMount: false,
    select: (data) => data?.data?.data ?? [],
    enabled: Boolean(uuid),
  });

  const getNumberName = useCallback(
    (number = '', withPlus = true, getNumberBack = true) => {
      if (!number) return 'Unknown number';
      const refData = numberList?.find((item) => {
        if (withPlus || number?.includes('+')) {
          return String(item?.value) === String(number);
        } else {
          return (
            String(item?.value)?.replace('+', '') === String(number)?.trim()
          );
        }
      });
      if (refData) {
        return `${refData?.name}`;
      } else {
        if (getNumberBack) {
          return formatPhoneNumber(number) || number;
        } else {
          return '';
        }
      }
    },
    [numberList],
  );

  return { ...rest, numberList, getNumberName };
}
