import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { initialValues, rateType, serviceTypes } from '../constants';
import useRateCardAdd from '../../../hooks/useRateCardAdd';
import useRateCardUpdate from '../../../hooks/useRateCardUpdate';
import FormInput from '../../../components/FormInput';
import FormSelect from '../../../components/FormSelect';
import Button from '../../../components/Button';
import CallRate from '../../../helpers/SampleFile/CallRate.csv';
import useIcons from '../../../assets/icons/useIcons';

const renderRateCardTypeItem = ({ item }) => {
  return (
    <option key={item?.value} value={item?.value}>
      {item?.label}
    </option>
  );
};
const renderRateCardServiceItem = ({ item }) => {
  return (
    <option key={item?.value} value={item?.value}>
      {item?.label}
    </option>
  );
};

const AddRateCard = ({ setOpenSlider, rowData }) => {
  const { UploadIcon } = useIcons();
  const { uuid } = rowData || {};
  const { mutateAsync: rateCardAddMutate, isLoading: rateCardAddLoad } =
    useRateCardAdd();
  const { mutateAsync: rateCardUpdateMutate, isLoading: rateCardUpdateLoad } =
    useRateCardUpdate();

  const validationSchema = yup.object({
    name: yup
      .string()
      .required('Name is required')
      .min(3, 'Name should be atleast 3 characters')
      .max(30, 'Name must be 3 to 30 characters'),
    type: !uuid && yup.string().required('Please select the type'),
    service: !uuid && yup.string().required('Please select the type'),
    csv_file: !uuid && yup.string().required('Please upload the required file'),
  });

  const {
    control,
    handleSubmit,
    getValues,
    register,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const handleSubmitForm = async (data) => {
    const { service, type, name } = data;
    if (uuid) {
      const payload = { name, type, service, uuid };
      await rateCardUpdateMutate(payload);
    } else {
      let formData = new FormData();
      // console.log(getValues('csv_file')?.[0], getValues('csv_file'));
      formData.append('file', getValues('csv_file')?.[0]);
      formData.append('service', service);
      formData.append('type', type);
      formData.append('name', name);
      await rateCardAddMutate(formData);
    }

    setOpenSlider(false);
    reset();
  };
  useEffect(() => {
    if (uuid) {
      const { name, type, service } = rowData;
      reset({ name, type, service });
    }
  }, [reset, rowData, uuid]);
  const isLoading = rateCardAddLoad || rateCardUpdateLoad;
  return (
    <form
      className="w--full d--flex flex--column gap--md"
      onSubmit={handleSubmit(handleSubmitForm)}
      autoComplete="off"
    >
      <div className="overflow--auto dialogScroll w--full d--flex flex--column gap--md p--md">
        <div>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="input"
                label="Name"
                placeholder="Enter name"
                error={errors?.name?.message}
                maxLength={25}
              />
            )}
          />
        </div>

        <div>
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <FormSelect
                {...field}
                options={rateType || []}
                renderOption={renderRateCardTypeItem}
                placeholder="Select"
                label="Type"
                error={errors.type?.message}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="service"
            control={control}
            render={({ field }) => (
              <FormSelect
                {...field}
                options={serviceTypes || []}
                renderOption={renderRateCardServiceItem}
                placeholder="Select Service"
                label="Service"
                error={errors.service?.message}
              />
            )}
          />
        </div>
        {!uuid && (
          <>
            <div
            // onDrop={(e) => {
            //   e.preventDefault();
            //   e.stopPropagation();
            //   const files = [...e.dataTransfer.files];
            //   if (files.length > 0) {
            //     setValue('csv_file', files);
            //   }
            // }}
            // onDragOver={(e) => {
            //   e.preventDefault();
            //   e.stopPropagation();
            // }}
            >
              <div>
                <label
                  className={`label--control font--sm font--500 m-b--xs 'text--black`}
                >
                  Csv File
                </label>
                <label
                  htmlFor="upload"
                  className=" Upload-file d--flex flex--column justify-content--center align-items--center   c--pointer position--relative "
                >
                  <div className="upload-text border-full--black-200 w--full d--flex justify-content--center align-items--center flex--column p--md h-min--150 gap--sm radius--sm">
                    <div className="uploadicon d--flex justify-content--center text--primary-400">
                      <UploadIcon width={45} height={45} />
                    </div>
                    <h3 className="font--md font--500">
                      Upload File or Drag & Drop
                    </h3>
                    <p className="font--sm">Supported Format .csv, .xlsx</p>
                    <p className="text--secondary ">
                      {getValues('csv_file')?.[0]?.name ?? ''}
                    </p>
                  </div>
                  <FormInput
                    {...register('csv_file')}
                    type="file"
                    id="upload"
                    accept=".csv"
                    hidden
                  />
                </label>

                <a
                  className="w-100  m-t--sm d--flex justify-content--end text--secondary gap--xs"
                  href={CallRate}
                  download
                >
                  <UploadIcon width={20} height={20} />
                  <span>Sample File</span>
                  <p className="text--secondary">{errors.csv_file?.message}</p>
                </a>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="d--flex justify-content--between gap--md p-r--md p-l--md">
        <Button
          type="button"
          variant="contrast"
          color="primary"
          btnClasses="btn"
          onClick={() => setOpenSlider(false)}
        >
          Cancel
        </Button>

        <Button
          type="submit"
          size=""
          variant="primary"
          color="white"
          btnClasses="btn"
          disabled={isLoading}
        >
          {isLoading ? 'Please wait...' : uuid ? 'Update' : 'Save'}
        </Button>
      </div>
    </form>
  );
};

export default AddRateCard;
