import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateUser } from '../../api';
import { useAlert } from '../useAlert';

export default function useUpdateUser(handleClose) {
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();
  const Request = useMutation({
    mutationFn: updateUser,
    mutationKey: 'updateUser',
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries('getUsers');
      showAlert({
        type: 'success',
        message: data?.message ?? 'User updated sucessfully',
      });
      handleClose();
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...Request };
}
