export const routes = {
  LOGIN: {
    METHOD: 'POST',
    URL: 'login',
  },
  GET_USERS: {
    METHOD: 'POST',
    URL: 'get-users',
  },
  LOGOUT: {
    METHOD: 'POST',
    URL: 'logout',
  },
  ADD_USER: {
    METHOD: 'POST',
    URL: 'add-user',
  },
  UPDATE_USER: {
    METHOD: 'POST',
    URL: 'update-user',
  },
  USER_ACTIVATION: {
    METHOD: 'POST',
    URL: 'activate-user',
  },
  USER_DEACTIVATION: {
    METHOD: 'POST',
    URL: 'deactivate-user',
  },
  ADMIN_DASHBOARD_STATUS: {
    METHOD: 'GET',
    URL: 'get-admin-dash-stats',
  },
  DID_LISTING: {
    METHOD: 'POST',
    URL: 'get-did-list',
  },
  DID_RELEASE: {
    METHOD: 'DELETE',
    URL: 'did-release',
  },
  TRANSACTION_LISTING: {
    METHOD: 'POST',
    URL: 'get-transactions',
  },
  PROVIDER_LISTING: {
    METHOD: 'POST',
    URL: 'get-providers',
  },
  ADD_PROVIDER: {
    METHOD: 'POST',
    URL: 'add-provider',
  },
  UPDATE_PROVIDER: {
    METHOD: 'POST',
    URL: 'update-provider',
  },
  DELETE_PROVIDER: {
    METHOD: 'DELETE',
    URL: 'delete-provider',
  },
  RATECARD_LISTING: {
    METHOD: 'POST',
    URL: 'get-rate-cards',
  },
  ADD_RATECARD: {
    METHOD: 'POST',
    URL: 'add-call-rates',
  },
  UPDATE_RATECARD: {
    METHOD: 'POST',
    URL: 'update-rate-card',
  },
  DELETE_RATECARD: {
    METHOD: 'DELETE',
    URL: 'delete-rate-card ',
  },

  CALL_RATES_LISTING: {
    METHOD: 'POST',
    URL: 'get-call-rates',
  },
  // ----------
  GET_AGENT_PROFILE_DETAILS: {
    METHOD: 'POST',
    URL: 'user/user-details',
  },
  GET_AGENT_MEMBERS: {
    METHOD: 'POST',
    URL: 'user/get-members',
  },
  GET_AGENT_AGENTS: {
    METHOD: 'POST',
    URL: 'user/get-agents',
  },
  GET_AGENT_AGENCIES: {
    METHOD: 'POST',
    URL: 'user/get-agencies',
  },
  GET_AGENT_AGENCIES_MEMBERS: {
    METHOD: 'POST',
    URL: 'user/get-agency-members',
  },
  GET_AGENT_CALLS: {
    METHOD: 'POST',
    URL: 'user/get-calls',
  },
  GET_AGENT_BILLING_DETAILS: {
    METHOD: 'POST',
    URL: 'user/billing-details',
  },
  GET_AGENT_PLAN_DETAILS: {
    METHOD: 'POST',
    URL: 'user/plan-details',
  },
  GET_AGENT_DID_NUMBERS: {
    METHOD: 'POST',
    URL: 'user/get-did-numbers',
  },
  GET_AGENT_ALL_DID_DROPDOWN: {
    METHOD: 'POST',
    URL: 'user/get-all-did-list',
  },
  GET_USER_ALL_IVR_DROPDOWN: {
    METHOD: 'POST',
    URL: 'user/get-all-ivr',
  },
  GET_USER_ALL_CONTACT_DROPDOWN: {
    METHOD: 'POST',
    URL: 'user/get-all-contacts',
  },
  GET_USER_ALL_RING_GROUP_DROPDOWN: {
    METHOD: 'POST',
    URL: 'user/get-all-ring-groups',
  },
  GET_USER_MEMBER_AGENT_DROPDOWN: {
    METHOD: 'POST',
    URL: 'user/get-all-member-agents',
  },
  ADD_AGENT: {
    METHOD: 'POST',
    URL: 'user/add-agent',
  },
  EDIT_AGENT: {
    METHOD: 'POST',
    URL: 'user/update-agent',
  },
  DELETE_AGENT: {
    METHOD: 'DELETE',
    URL: 'user/delete-agent',
  },
  // ---------------
  ADD_EMPLOYEE: {
    METHOD: 'POST',
    URL: 'user/add-member',
  },
  EDIT_EMPLOYEE: {
    METHOD: 'POST',
    URL: 'user/update-member',
  },
  DELETE_EMPLOYEE: {
    METHOD: 'DELETE',
    URL: 'user/delete-member',
  },
  // ----------
  GET_PLANS: {
    METHOD: 'POST',
    URL: 'get-plans',
  },
  ADD_PLAN: {
    METHOD: 'POST',
    URL: 'add-plan',
  },
  UPDATE_PLAN: {
    METHOD: 'POST',
    URL: 'update-plan',
  },
  GET_CALLS: {
    METHOD: 'POST',
    URL: 'user/get-calls',
  },
  GET_PARTICULAR_CALL_HISTORY: {
    METHOD: 'POST',
    URL: 'user/get-calls-by-id',
  },
  GET_SMS: {
    METHOD: 'POST',
    URL: 'user/get-sms',
  },
  ACTIVATE_PLAN: {
    METHOD: 'POST',
    URL: 'activate-plan',
  },
  DEACTIVATE_PLAN: {
    METHOD: 'POST',
    URL: 'deactivate-plan',
  },
  PORTING_LIST: {
    METHOD: 'POST',
    URL: 'porting/listing',
  },
  PORTING_STATUS_UPDATE: {
    METHOD: 'POST',
    URL: 'porting',
  },
  USER_LOGIN: {
    METHOD: 'POST',
    URL: 'user-login',
  },
  SUPPORT_LISTING: {
    METHOD: 'POST',
    URL: 'support/listing',
  },
  // Dropdown APIS

  RATE_CARD_DROPDOWN: {
    METHOD: 'POST',
    URL: 'get-all-rate-cards',
  },
  PROVIDER_DROPDOWN: {
    METHOD: 'POST',
    URL: 'get-all-providers',
  },
  PLAN_DROPDOWN: {
    METHOD: 'POST',
    URL: 'get-all-plans',
  },
  GET_ALL_USERS: {
    METHOD: 'POST',
    URL: 'get-all-users',
  },
  ALL_NUMBER_DETAILS: {
    METHOD: 'POST',
    URL: 'user/get-account-data',
  },
};
