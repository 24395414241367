import React, { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import Error500Page from '../pages/Error500Page';
import Login from '../pages/Login';
import Users from '../pages/Users';
import PageNotFound from '../pages/PageNotFound';
import OuterLayout from '../layouts/OuterLayout';
import Dashboard from '../pages/Dashboard';
import VirtualNumber from '../pages/VirtualNumber';
import Transaction from '../pages/Transaction';
import Providers from '../pages/Provider';
import RateCard from '../pages/RateCard';
import UserDetails from '../pages/UserDetails';

import Profile from '../pages/UserDetails/Contents/Profile';
import Agency from '../pages/UserDetails/Contents/Agency';
import MemberAgents from '../pages/UserDetails/Contents/MemberAgents';
import DIDNumber from '../pages/UserDetails/Contents/DIDNumber';
import Call from '../pages/UserDetails/Contents/Call';
import DIDBilling from '../pages/UserDetails/Contents/DIDBilling';
import PlanBilling from '../pages/UserDetails/Contents/PlanBilling';

import UserInfoLayout from '../layouts/UserInfoLayout';
import Plans from '../pages/Plans';
import Calls from '../pages/Calls';
import SMS from '../pages/SMS';
import Support from '../pages/Support';
import PortRequests from '../pages/PortRequests';
import CallListingLayout from '../pages/CallListingLayout';
import Voicemail from '../pages/CallListingLayout/Voicemail';
import AllCalls from '../pages/CallListingLayout/AllCalls';
import Recordings from '../pages/CallListingLayout/Recordings';
import MissedCalls from '../pages/CallListingLayout/MissedCalls';
import IncomingCalls from '../pages/CallListingLayout/IncomingCalls';
import OutgoingCalls from '../pages/CallListingLayout/OutgoingCalls';

const AuthRemover = lazy(() => import('../auth/AuthRemover'));
const AuthProvider = lazy(() => import('../auth/AuthProvider'));
const AuthLayout = lazy(() => import('../layouts/AuthLayout'));

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <AuthProvider>
        <AuthLayout />
      </AuthProvider>
    ),
    errorElement: <Error500Page />,
    children: [
      {
        path: 'users',
        element: <Users />,
        id: 'Users',
      },
      {
        path: 'dashboard',
        element: <Dashboard />,
        id: 'Dashboard',
      },
      {
        path: 'did-number',
        element: <VirtualNumber />,
        id: 'VirtualNumber',
      },
      {
        path: 'port-requests',
        element: <PortRequests />,
        id: 'PortRequests',
      },
      {
        path: 'transaction',
        element: <Transaction />,
        id: 'Transaction',
      },
      {
        path: 'provider',
        element: <Providers />,
        id: 'Provider',
      },
      {
        path: 'rate-card',
        element: <RateCard />,
        id: 'RateCard',
      },
      {
        path: 'plans',
        element: <Plans />,
        id: 'Plans',
      },
      // {
      //   path: 'calls',
      //   element: <Calls />,
      //   id: 'Calls',
      // },
      {
        path: 'calls',
        element: <CallListingLayout />,
        id: 'Calls',
        children: [
          {
            index: true,
            path: 'all-calls',
            element: <AllCalls />,
          },
          {
            path: 'voicemails',
            element: <Voicemail />,
          },
          {
            path: 'recordings',
            element: <Recordings />,
          },
          {
            path: 'missed-calls',
            element: <MissedCalls />,
          },
          {
            path: 'incoming-calls',
            element: <IncomingCalls />,
          },
          {
            path: 'outgoing-calls',
            element: <OutgoingCalls />,
          },
        ],
      },
      {
        path: 'sms',
        element: <SMS />,
        id: 'SMS',
      },
      {
        path: 'support',
        element: <Support />,
        id: 'Support',
      },
    ],
  },
  {
    path: 'user-info',
    element: (
      <AuthProvider>
        <UserInfoLayout />
      </AuthProvider>
    ),
    errorElement: <Error500Page />,
    children: [
      {
        path: ':id',
        element: <UserDetails />,
        children: [
          {
            index: true,
            path: 'profile',
            element: <Profile />,
          },
          {
            path: 'member',
            element: <MemberAgents />,
          },
          {
            path: 'agency',
            element: <Agency />,
          },
          {
            path: 'did-number',
            element: <DIDNumber />,
          },
          {
            path: 'call',
            element: <Call />,
          },
          {
            path: 'did-billing',
            element: <DIDBilling />,
          },
          {
            path: 'plan-billing',
            element: <PlanBilling />,
          },
        ],
      },
    ],
  },
  {
    path: '/',
    element: (
      <AuthRemover>
        <OuterLayout />
      </AuthRemover>
    ),
    errorElement: <Error500Page />,
    children: [
      {
        index: true,
        element: <Login />,
        id: 'Login',
      },
    ],
  },

  {
    path: '*',
    element: <PageNotFound />,
  },
]);
