import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './styles/styles.scss';
import 'react-phone-number-input/style.css';
import App from './App';
import Spinner from './components/Spinner';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Suspense
    fallback={
      <div className="d--flex align-items--center justify-content--center h--full w--full">
        <Spinner size="lg" />
      </div>
    }
  >
    <App />
  </Suspense>,
);
