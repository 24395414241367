import React, { forwardRef } from 'react';

function ControlledToolTip({ title = '' }, ref) {
  return (
    <div
      ref={ref}
      // position="left"
      className={`position--absolute bg--primary text--white z-index--md p--md p-l--md p-r--md radius--sm gap--sm d--flex flex--column  m-l--md w-min--150 left--100   `}
    >
      <span className="w--full d--flex align-items--center justify-content--center">
        <div className="w--full h-max--250 overflow--auto">{title}</div>
        <div className="w-min--12 h-min--12 rotate--45 bg--primary position--absolute left---5"></div>
      </span>
    </div>
  );
}

export default forwardRef(ControlledToolTip);
