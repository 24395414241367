import React, { Fragment, useEffect, useState } from 'react';
import useIcons from '../../assets/icons/useIcons';
import TableManager from '../../components/TableManager';
import { providerListing } from '../../api';
import Dropdown from '../../components/Dropdown';
import useProviderDelete from '../../hooks/useProviderDelete';
import AsideModal from '../../components/AsideModal';
import AddProvider from './Modal/AddProvider';
import Button from '../../components/Button';
import useRateCardDropdown from '../../hooks/useRateCardDropdown';

const Providers = () => {
  const [openSlider, setOpenSlider] = useState(false);
  const [rowData, setRowData] = useState({});
  const { MoreVIcon, TrashIcon, PencilIcon } = useIcons();
  const { mutate: mutateProviderDelete } = useProviderDelete();
  const { mutateAsync: rateCardsMutate, data: rateCardData } =
    useRateCardDropdown();
  useEffect(() => {
    rateCardsMutate({ type: 'Buy' });
  }, []);
  const tableActions = [
    {
      id: 1,
      component: (
        <Button
          type="button"
          btnClasses="btn w-min--150"
          variant="primary"
          color="white"
          onClick={() => {
            setOpenSlider(true);
            setRowData({});
          }}
        >
          Add Provider
        </Button>
      ),
    },
  ];

  const handleEditData = (data) => {
    setOpenSlider(true);
    setRowData(data);
  };
  const deleteUserList = (data) => {
    mutateProviderDelete({ uuid: data?.uuid });
  };

  const handleDropdownClick = ({ value = '' }, data) => {
    switch (value) {
      case 'edit':
        handleEditData(data);
        break;
      case 'delete':
        deleteUserList(data);
        break;
      default:
        break;
    }
  };
  const filterInitialValues = {
    filter: [
      {
        name: '',
        a_filter_type: '',
        placeholder: 'Name',
        type: 'text',
        shouldFilterOptionDisabled: false,
        label: 'Name',
        filterName: 'name',
        position: 1,
      },

      {
        request_mode: '',
        a_filter_type: '',
        placeholder: 'Enter mode',
        type: 'text',
        shouldFilterOptionDisabled: false,
        label: 'mode',
        filterName: 'request_mode ',
        position: 3,
      },
    ],
  };
  const columns = [
    {
      accessorKey: 'name',
      header: () => 'Name',
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'add_prefix',
      header: () => 'Add Prefix',
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'remove_prefix',
      header: () => 'Remove Prefix',
      meta: {
        textAlign: 'center',
      },
    },

    {
      accessorKey: 'request_mode',
      header: () => 'Mode',
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'host_ip_inbound',
      header: () => 'Inbound IP',
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'host_ip_outbound',
      header: () => 'Outbound IP',
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'allow_codecs',
      header: () => 'Allow Codecs',
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'status',
      header: () => 'Status',
      cell: (props) => {
        if (props?.getValue() === 'A') {
          return (
            <div
              data-tooltip={'Active'}
              tooltip-position="top"
              className="bg--success radius--full p-l--sm  p-r--sm w-min--20 h-min--20 w-max--20 h-max--20  text--white d--flex justify-content--center align-items--center"
            >
              A
            </div>
          );
        } else {
          return (
            <div
              data-tooltip={'Deactive'}
              tooltip-position="top"
              className="bg--secondary radius--full p-l--sm p-r--sm w-min--20 h-min--20 w-max--20 h-max--20 text--white d--flex justify-content--center align-items--center"
            >
              D
            </div>
          );
        }
      },
      meta: {
        textAlign: 'left',
      },
    },

    {
      accessorKey: 'action',
      header: () => 'Action',
      meta: {
        width: '5%',
      },
      cell: (props) => {
        const element = props?.row?.original || {};
        const dropDownOptions = [
          { title: 'Edit', value: 'edit' },
          { title: 'Delete', value: 'delete' },
        ];
        return (
          <span>
            <Dropdown
              closeOnClickOutside={true}
              showcaret={false}
              dropList={{
                component: ({ item }) => (
                  <div
                    className="w--full d--flex  justify-content--start gap--sm align-items--center p--sm"
                    onClick={() => {
                      handleDropdownClick(item, element);
                    }}
                  >
                    {item?.title}
                  </div>
                ),
                data: dropDownOptions,
              }}
              extraClasses="w-min--150"
            >
              <span className="w-min--32 h-min--32 d--flex align-items--center justify-content--center radius--sm bg--black-100 text--black  c--pointer">
                <MoreVIcon width={15} height={15} />
              </span>
            </Dropdown>
          </span>
        );
      },
    },
  ];
  return (
    <Fragment>
      <div className="w--full">
        <TableManager
          {...{
            name: 'Provider',
            fetcherKey: 'providerListing',
            fetcherFn: providerListing,
            columns,
            tableActions,
            initialValues: filterInitialValues,
            shouldShowTotalCount: true,
          }}
        />
        {openSlider && (
          <AsideModal
            handleClose={() => {
              setRowData({});
              setOpenSlider(false);
            }}
            title={`${rowData?.uuid ? 'Update' : 'Add'} Provider`}
            footerComponent={null}
          >
            <AddProvider
              setOpenSlider={setOpenSlider}
              rowData={rowData}
              rateCardData={rateCardData?.data?.data}
            />
          </AsideModal>
        )}
      </div>
    </Fragment>
  );
};

export default Providers;
