import React, { useState } from 'react';
import TableManager from '../../components/TableManager';
import { supportListing } from '../../api';
import { formatDate } from '../../helpers/functions';
import Modal from '../../components/Modal';

const priorityMap = {
  L: 'Low',
  M: 'Medium',
  H: 'High',
};
const Support = () => {
  const [openDescriptionModal, setOpenDescriptionModal] = useState({
    isShow: false,
    content: '',
  });

  const columns = [
    {
      accessorKey: 'created_at',
      header: () => 'Date',
      cell: (props) => formatDate(props.getValue()),

      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'user.account_number',
      header: () => 'Account No',
      cell: (props) => {
        const {
          account_number = '',
          first_name = '',
          email = '',
        } = props?.row?.original?.user || {};
        return (
          <span
            data-tooltip={`
                ${first_name}
                ${email}
              `}
            tooltip-position="right"
            className="c--pointer text--danger font--600 d--flex gap--sm align-items--center justify-content--center"
          >
            {account_number}
          </span>
        );
      },
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'ticket_id',
      header: () => 'Ticket ID',
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'priority',
      header: () => 'Priority',
      cell: (props) => {
        return <>{priorityMap[props?.getValue()]}</>;
      },
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'issue_type',
      header: () => 'Issue Type',
      meta: {
        textAlign: 'left',
      },
    },

    {
      accessorKey: 'description',
      header: () => 'Description',
      cell: (props) => {
        return (
          <>
            {props.getValue() ? (
              props.getValue()?.length > 25 ? (
                <div
                  className="text--sm c--pointer"
                  onClick={() => {
                    setOpenDescriptionModal({
                      isShow: true,
                      content: props.getValue(),
                    });
                  }}
                >
                  {props.getValue()?.slice(0, 25)}...
                </div>
              ) : (
                props.getValue()
              )
            ) : (
              '---'
            )}
          </>
        );
      },
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'attachment_file',
      header: () => 'Attachment File',
      cell: (props) => {
        const element = props?.row?.original;
        const url = `${process.env.REACT_APP_MEDIA_URL}${
          element?.user?.parent_uuid || element?.user?.uuid
        }/support?filename=${element?.attachment_file}`;
        return (
          <>
            {element?.attachment_file ? (
              <a href={url} target="_blank" rel="noreferrer">
                Preview
              </a>
            ) : (
              '---'
            )}
          </>
        );
      },
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'status',
      header: () => 'Status',
      cell: (props) => {
        return <>{props?.getValue() === 'O' ? 'Open' : 'Close'}</>;
      },
      meta: {
        textAlign: 'left',
      },
    },

    // {
    //   accessorKey: 'action',
    //   header: () => 'Action',
    // },
  ];

  const { isShow = false, content = '' } = openDescriptionModal;

  return (
    <>
      <div className="px-3">
        <TableManager
          {...{
            name: 'Support',
            fetcherKey: 'supportListing',
            fetcherFn: supportListing,
            columns,
            shouldShowTotalCount: true,
          }}
        />
      </div>
      {isShow ? (
        <Modal
          headerComponent={true}
          title="Description"
          handleClose={() =>
            setOpenDescriptionModal({ isShow: false, content: '' })
          }
          shouldCloseOnClickOutside={true}
          height="75"
          radius="full"
          footerComponent={
            <>
              <button
                className="btn btn--secondary btn--md"
                onClick={() =>
                  setOpenDescriptionModal({ isShow: false, content: '' })
                }
              >
                Close
              </button>
            </>
          }
        >
          <div
            style={{
              wordBreak: 'break-all',
            }}
            className="w--full h--full h-min--60 d--flex align-items--center justify-content--center"
          >
            {content}
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export default Support;
