import React, { useEffect } from 'react';
import FormInput from '../../../components/FormInput';
import FormSelect from '../../../components/FormSelect';
import Button from '../../../components/Button';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import useCountryList from '../../../hooks/useCountryList';
import PhoneInput from 'react-phone-number-input';
import useAddUser from '../../../hooks/useAddUser';
import useUpdateUser from '../../../hooks/useUpdateUser';
import useGetPlans from '../../../hooks/useGetPlans';

const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  password: '',
  country: 'US',
  timezone: '',
  tenant_company: '',
  tenant_fein: '',
  tenant_npn: '',
  plan_uuid: '',
};

const validationSchema = yup.object().shape({
  first_name: yup
    .string()
    .required('First name is required')
    .matches(/^[A-Za-z'-]+[ A-Za-z'-]*$/, 'First name cannot contain numbers'),
  last_name: yup
    .string()
    .required('Last name is required')
    .matches(/^[A-Za-z'-]+[ A-Za-z'-]*$/, 'Last name cannot contain numbers'),
  email: yup.string().email('Email is invalid').required('Email is required'),
  phone: yup.string().required('Phone Number is required'),
  password: yup
    .string()
    .required('Password field is required.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
    ),
  timezone: yup.string().required('Timezone is required'),
  plan_uuid: yup.string().required('Plan is required'),
  tenant_company: yup
    .string()
    .required('Tenant company is required')
    .matches(
      /^[A-Za-z'-]+[ A-Za-z'-]*$/,
      'Tenant company cannot contain numbers',
    ),
  tenant_npn: yup
    .string()
    .matches(/^[0-9]+$/, 'Tenant npn must contain only  numbers')
    .required('Tenant npn is required'),
  tenant_fein: yup
    .string()
    .required('Tenant fein is required')
    .matches(
      /^[A-Za-z0-9]+$/,
      'Tenant fein must contain only letters and numbers',
    ),
});

const RenderTimeZoneItem = ({ item }) => {
  return (
    <option key={item.abbreviation} value={item.abbreviation}>
      {item.zoneName}
    </option>
  );
};
const RenderPlanItem = ({ item }) => {
  return (
    <option key={item.uuid} value={item.uuid}>
      {item.plan_name}
    </option>
  );
};
export default function AddUser({
  editUserInstance = {},
  handleClose = () => null,
}) {
  const countries = useCountryList();
  const { mutateAsync, data } = useGetPlans();
  const {
    watch,
    getValues,
    setValue,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  });

  useEffect(() => {
    mutateAsync();
  }, []);
  const { mutate: mutateAdd, isLoading: loadAdd } = useAddUser(handleClose);
  const { mutate: mutateUpdate, isLoading: loadUpdate } =
    useUpdateUser(handleClose);

  useEffect(() => {
    const { isEdit = false, editData = {} } = editUserInstance;
    if (
      isEdit &&
      editData &&
      Object.keys(editData) &&
      Object.keys(editData).length > 0
    ) {
      reset(editData);
    }
  }, []);

  async function onSubmit(values) {
    const { isEdit = false, editData = {} } = editUserInstance;

    if (isEdit) {
      values.uuid = editData?.uuid;
      mutateUpdate(values);
    } else {
      mutateAdd(values);
    }
  }

  const selectedCountry = watch('country');

  const timezones = selectedCountry
    ? countries?.find((country) => country.isoCode === selectedCountry)
        ?.timezones
    : [];

  const loading = isSubmitting || loadAdd || loadUpdate;
  return (
    <form
      className="w--full d--flex flex--column gap--md"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <div className="overflow--auto dialogScroll w--full d--flex flex--column gap--md p--md">
        <div className="d--flex gap--md">
          <Controller
            name="first_name"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="input"
                label="First Name"
                placeholder="Enter your first name"
                error={errors?.first_name?.message}
              />
            )}
          />
        </div>
        <div className="d--flex gap--md">
          <Controller
            name="last_name"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="input"
                label="Last Name"
                placeholder="Enter your last name"
                error={errors?.last_name?.message}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="input"
                label="Principal Email"
                placeholder="Enter your email address"
                error={errors?.email?.message}
              />
            )}
          />
        </div>
        <div>
          <label
            className={`label--control font--sm font--500 m-b--xs ${
              errors?.phone?.message ? 'text--danger' : 'text--black'
            }`}
          >
            {errors?.phone?.message ?? 'Principal Phone'}
          </label>

          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <PhoneInput
                {...field}
                control={control}
                defaultCountry={watch('country')}
                international={true}
                addInternationalOption={false}
                withCountryCallingCode={true}
                countryCallingCodeEditable={false}
                placeholder="Enter your phone"
                internationalIcon={() => null}
                flagComponent={() => null}
                countrySelectComponent={() => null}
                value={getValues(`phone`)}
                limitMaxLength={true}
                onChange={(phone) => setValue(`phone`, phone)}
                className={`form--control w--full h-min--36  radius--sm p-l--md p-r--md  ${
                  errors?.phone?.message
                    ? 'border-full--danger'
                    : 'border-full--black-200'
                }`}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="timezone"
            control={control}
            render={({ field }) => (
              <FormSelect
                {...field}
                options={timezones || []}
                renderOption={RenderTimeZoneItem}
                placeholder="Select"
                label="Timezone"
                error={errors?.timezone?.message}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="password"
                label="Password"
                placeholder="Enter your password"
                error={errors?.password?.message}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="tenant_company"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="input"
                label="Tenant Company"
                placeholder="Enter tenant company"
                error={errors?.tenant_company?.message}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="tenant_npn"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="input"
                label="Tenant NPN"
                placeholder="Enter tenant NPN"
                error={errors?.tenant_npn?.message}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="tenant_fein"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="input"
                label="Tenant FEIN"
                placeholder="Enter tenant FEIN"
                error={errors?.tenant_fein?.message}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="plan_uuid"
            control={control}
            render={({ field }) => (
              <FormSelect
                {...field}
                options={data?.data?.data || []}
                renderOption={RenderPlanItem}
                placeholder="Select"
                label="Plan"
                error={errors?.plan_uuid?.message}
              />
            )}
          />
        </div>
      </div>
      <div className="d--flex justify-content--between gap--md p-l--md p-r--md">
        <Button
          type="button"
          variant="contrast"
          color="primary"
          btnClasses="btn"
          onClick={() => handleClose()}
        >
          Cancel
        </Button>

        <Button
          type="submit"
          size=""
          variant="primary"
          color="white"
          btnClasses="btn"
          disabled={loading}
        >
          {loading ? 'Please wait..' : 'Save'}
        </Button>
      </div>
    </form>
  );
}
