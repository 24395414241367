import * as yup from 'yup';

export const initialValues = {
  plan_name: '',
  service_type: 'P',
  // custom_plan: 'No',

  //pbx
  plan_countries: ['1'],
  free_countries: ['1'],
  free_calls: '',
  members: '',
  member_cost: '',
  free_sms: '',
  group: '',
  ivr: '',

  //Did
  did: '',
  did_cost: '',

  // //storage
  // storage: '',
  // storage_cost: '',

  //ratecard
  inbound_ratecard_uuid: '',
  outbound_ratecard_uuid: '',
  call_carriers_uuid: [],

  // plan cost
  // free_trial: 0,
  monthly_cost: 0.0,
  // quarterly_cost: 0.0,
  yearly_cost: 0.0,
};
export const validationSchema = yup.object({
  plan_name: yup.string().required('Name  is required'),
  service_type: yup.string().required('Service type  is required'),
  custom_plan: yup.string().required('Custom Plan  is required'),

  //video
  // maximum_meeting_duration: yup.string().when('service_type', {
  //   is: (val) => val === 'B' || val === 'V',
  //   then: () => yup.string().required('Meeting Duration  is required'),
  //   otherwise: () => yup.string().nullable(),
  // }),
  // participants: yup.string().when('service_type', {
  //   is: (val) => val === 'B' || val === 'V',
  //   then: () =>
  //     yup
  //       .number()
  //       .required('Participants is required')
  //       .min(0, 'Value must be greater than or equal to 0')
  //       .typeError('Participants is required'),
  //   otherwise: () => yup.string().nullable(),
  // }),
  // translation: yup.string().when('service_type', {
  //   is: (val) => val === 'B' || val === 'V',
  //   then: () => yup.string().required('Translation is required'),
  //   otherwise: () => yup.string().nullable(),
  // }),
  // push_to_talk: yup.string().when('service_type', {
  //   is: (val) => val === 'B' || val === 'V',
  //   then: () => yup.string().required('Translation is required'),
  //   otherwise: () => yup.string().nullable(),
  // }),

  //pbx
  plan_countries: yup.mixed().when('service_type', {
    is: (val) => val === 'B' || val === 'P',
    then: () =>
      yup
        .array()
        .min(1, 'Select atleast one country')
        .typeError('Select atleast one country'),
    otherwise: () => yup.mixed().nullable(),
  }),
  free_countries: yup.mixed().when('service_type', {
    is: (val) => val === 'B' || val === 'P',
    then: () =>
      yup
        .array()
        .min(1, 'Select atleast one country')
        .typeError('Select atleast one country'),
    otherwise: () => yup.mixed().nullable(),
  }),
  did: yup.string().when('service_type', {
    is: (val) => val === 'B' || val === 'P',
    then: () =>
      yup
        .number()
        .required('DID is required')
        .min(0, 'Value must be greater than or equal to 0')
        .typeError('DID is required'),
    otherwise: () => yup.string().nullable(),
  }),
  members: yup.string().when('service_type', {
    is: (val) => val === 'B' || val === 'P',
    then: () =>
      yup
        .number()
        .required('Members is required')
        .min(0, 'Value must be greater than or equal to 0')
        .typeError('Members is required'),
    otherwise: () => yup.string().nullable(),
  }),
  member_cost: yup.string().when('service_type', {
    is: (val) => val === 'B' || val === 'P',
    then: () =>
      yup
        .number()
        .required('Member cost is required')
        .min(0, 'Value must be greater than or equal to 0')
        .typeError('Member cost is required'),
    otherwise: () => yup.string().nullable(),
  }),
  free_calls: yup.string().when('service_type', {
    is: (val) => val === 'B' || val === 'P',
    then: () =>
      yup
        .number()
        .required('Free calls is required')
        .min(0, 'Value must be greater than or equal to 0')
        .typeError('Free calls is required'),
    otherwise: () => yup.string().nullable(),
  }),
  free_sms: yup.string().when('service_type', {
    is: (val) => val === 'B' || val === 'P',
    then: () =>
      yup
        .number()
        .required('Free sms is required')
        .min(0, 'Value must be greater than or equal to 0')
        .typeError('Free sms is required'),
    otherwise: () => yup.string().nullable(),
  }),
  group: yup.string().when('service_type', {
    is: (val) => val === 'B' || val === 'P',
    then: () => yup.string().required('Group is required'),
    otherwise: () => yup.string().nullable(),
  }),
  ivr: yup.string().when('service_type', {
    is: (val) => val === 'B' || val === 'P',
    then: () => yup.string().required('IVR is required'),
    otherwise: () => yup.string().nullable(),
  }),

  //storage
  storage: yup
    .number()
    .required('Storage is required')
    .min(0, 'Value must be greater than or equal to 0')
    .typeError('Storage is required'),
  storage_cost: yup
    .number()
    .required('Cost is required')
    .min(0, 'Value must be greater than or equal to 0')
    .typeError('Cost is required'),

  //plan cost

  monthly_cost: yup
    .number()
    .required('Cost is required')
    .min(0, 'Value must be greater than or equal to 0')
    .typeError('Cost is required'),
  quarterly_cost: yup
    .number()
    .required('Cost is required')
    .min(0, 'Value must be greater than or equal to 0')
    .typeError('Cost is required'),
  yearly_cost: yup
    .number()
    .required('Cost is required')
    .min(0, 'Value must be greater than or equal to 0')
    .typeError('Cost is required'),

  inbound_ratecard_uuid: yup
    .string()
    .required('Please select at least one card'),
  //  yup.object().when('service_type', {
  //   is: (val) => val === 'B' || val === 'P',
  //   then: () =>
  //     yup
  //       .object()
  //       .shape({
  //         label: yup.string().required('Please select at least one card'),
  //       })
  //       .typeError('Please select at least one card'),
  //   otherwise: () => yup.mixed().nullable(),
  // }),
  outbound_ratecard_uuid: yup
    .string()
    .required('Please select at least one card'),
  // yup.object().when('service_type', {
  // is: (val) => val === 'B' || val === 'P',
  // then: () =>
  //   yup
  //     .object()
  //     .shape({
  //       label: yup.string().required('Please select at least one card'),
  //     })
  //     .typeError('Please select at least one card'),
  // otherwise: () => yup.mixed().nullable(),
  // }),
  call_carriers_uuid: yup.array().when('service_type', {
    is: (val) => val === 'B' || val === 'P',
    then: () =>
      yup
        .array()
        .min(1, 'Please select at least one code')
        .typeError('Please select atleast one carrier'),
    otherwise: () => yup.array().nullable(),
  }),
});
export const addPlanValidationSchema = yup.object({
  plan_name: yup.string().required('Plan name  is required'),
  //pbx
  plan_countries: yup
    .array()
    .min(1, 'Select atleast one country')
    .typeError('Select atleast one country'),
  free_countries: yup
    .array()
    .min(1, 'Select atleast one country')
    .typeError('Select atleast one country'),
  free_calls: yup
    .number()
    .required('Free calls is required')
    .min(0, 'Value must be greater than or equal to 0')
    .typeError('Free calls is required'),
  members: yup
    .number()
    .required('No of agents is required')
    .min(0, 'Value must be greater than or equal to 0')
    .typeError('No of agents is required'),
  member_cost: yup
    .number()
    .required('Member cost is required')
    .min(0, 'Value must be greater than or equal to 0')
    .typeError('Member cost is required'),
  free_sms: yup
    .number()
    .required('Free sms is required')
    .min(0, 'Value must be greater than or equal to 0')
    .typeError('Free sms is required'),

  group: yup.string().required('Department is required'),
  ivr: yup.string().required('IVR is required'),
  did: yup
    .number()
    .required('DID is required')
    .min(0, 'Value must be greater than or equal to 0')
    .typeError('DID is required'),
  did_cost: yup
    .number()
    .required('Cost is required')
    .min(0, 'Value must be greater than or equal to 0')
    .typeError('Cost is required'),

  //plan cost

  monthly_cost: yup
    .number()
    .required('Monthly cost is required')
    .min(0, 'Value must be greater than or equal to 0')
    .typeError('Monthly cost is required'),

  yearly_cost: yup
    .number()
    .required('Yearly cost is required')
    .min(0, 'Value must be greater than or equal to 0')
    .typeError('Yearly cost is required'),

  inbound_ratecard_uuid: yup
    .string()
    .required('Please select at least one card'),

  outbound_ratecard_uuid: yup
    .string()
    .required('Please select at least one card'),

  call_carriers_uuid: yup.array().when('service_type', {
    is: (val) => val === 'B' || val === 'P',
    then: () =>
      yup
        .array()
        .min(1, 'Please select at least one code')
        .typeError('Please select atleast one carrier'),
    otherwise: () => yup.array().nullable(),
  }),
});
export const serviceTypes = [
  { label: 'Business', value: 'B' },
  { label: 'Video', value: 'V' },
  { label: 'PBX', value: 'P' },
];
export const radioTypes = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
];
export const planDurationTypes = [
  { value: 'M', label: 'Monthly' },
  { value: 'Q', label: 'Quarterly' },
  { value: 'Y', label: 'Yearly' },
];
export const durationTypes = [
  { value: '30', label: '30 min' },
  { value: '45', label: '45 min' },
  { value: '60', label: '1 hr' },
  { value: '120', label: '2 hr' },
  { value: '180', label: '3 hr' },
  { value: '240', label: '4 hr' },
  { value: '300', label: '5 hr' },
];
