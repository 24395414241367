import { useMutation } from '@tanstack/react-query';
import { getPlans } from '../../api';
import { useAlert } from '../useAlert';

export default function useGetPlans() {
  const { showAlert } = useAlert();
  const Request = useMutation({
    mutationFn: getPlans,
    mutationKey: 'getPlans',

    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...Request };
}
