import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import FormSelect from '../../components/FormSelect';
import { useState, useEffect } from 'react';
import useGetAllUsers from '../../hooks/useGetAllUsers';

const RenderSearchItem = ({ item }) => {
  return (
    <option key={item.uuid} value={item?.uuid}>
      {item?.name}
    </option>
  );
};

const CallListingLayout = () => {
  const [searchPayload, setSearchPayload] = useState('');
  const { mutate: allUsersMutate, data: allUsersData } = useGetAllUsers();

  useEffect(() => {
    allUsersMutate();
  }, []);

  return (
    <main className="d--flex w--full h--full p--md gap--md bg--contrast position--relative">
      <div className="w--full h--full d--flex flex--column gap--sm">
        <div className="h--full w--full  ">
          <div className="d--flex gap--md h--full call-listing">
            <Sidebar />
            <div className="w--full d--flex flex--column align-items--center">
              <div className="w--full w-max--300">
                <FormSelect
                  extraClasses="border--0"
                  defaultOption={true}
                  options={allUsersData?.data?.data || []}
                  value={searchPayload}
                  renderOption={RenderSearchItem}
                  onChange={(e) => setSearchPayload(e.target.value)}
                />
              </div>
              <Outlet context={{ selected_user_id: searchPayload }} />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default CallListingLayout;
