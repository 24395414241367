import React, { useState } from 'react';
import TableManager from '../../components/TableManager';
import { portingList } from '../../api';
import useReleaseDid from '../../hooks/useReleaseDid';
import useIcons from '../../assets/icons/useIcons';
import Dropdown from '../../components/Dropdown';
import AsideModal from '../../components/AsideModal';
import PreviewPortRequest from './PreviewPortRequest';
import { formatDateOnly } from '../../helpers/functions';
import usePortingStatusUpdate from '../../hooks/usePortingStatusUpdate';

const statusOptions = [
  { title: 'Pending', value: 'Pending' },
  { title: 'In Process', value: 'Process' },
  { title: 'Completed', value: 'Approved' },
  { title: 'Cancelled', value: 'Rejected' },
];

const PortRequests = () => {
  const [previewModal, setPreviewModal] = useState({ show: false, data: null });
  const { mutate: mutateReleaseDid } = useReleaseDid();
  const { mutate: updatePortStatusMutate } = usePortingStatusUpdate();
  const { MoreVIcon } = useIcons();

  const deleteUserList = (data) => {
    mutateReleaseDid({ uuid: data?.uuid });
  };

  const handleDropListClick = (element, value) => {
    switch (value) {
      case 'preview':
        setPreviewModal({ show: true, data: element });
        break;
      case 'Process':
      case 'Approved':
      case 'Rejected':
        updatePortStatusMutate({ id: element?.id, status: value });
        break;

      default:
        return;
    }
  };
  const filterInitialValues = {
    filter: [
      {
        did_number: '',
        a_filter_type: '',
        placeholder: 'Enter port number',
        type: 'text',
        shouldFilterOptionDisabled: false,
        label: 'Port number',
        filterName: 'did_number',
        position: 1,
      },
      {
        number_type: '',
        a_filter_type: '',
        placeholder: 'Enter number type',
        type: 'select',
        shouldFilterOptionDisabled: false,
        label: 'Number Type',
        selectOptions: [
          { label: 'Local', value: 'Local' },
          { label: 'Toll-Free', value: 'Toll-free' },
        ],
        filterName: 'number_type',
        position: 2,
      },
    ],
  };

  const columns = [
    {
      accessorKey: 'did_number',
      header: () => 'Virtual Number',
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'user',
      header: () => 'Requested By',
      meta: {
        textAlign: 'center',
      },
      cell: (props) => {
        const {
          account_number = '',
          first_name = '',
          email = '',
        } = props?.row?.original?.user || {};
        return (
          <span
            data-tooltip={`
                ${first_name}
                ${email}
              `}
            tooltip-position="right"
            className="c--pointer text--danger font--600 d--flex gap--sm align-items--center justify-content--center"
          >
            {account_number}
          </span>
        );
      },
    },
    {
      accessorKey: 'country',
      header: () => 'Country',
    },
    {
      accessorKey: 'number_type',
      header: () => 'Type',
    },
    {
      accessorKey: 'created_at',
      header: () => 'Requested on',
      cell: (props) => formatDateOnly(props?.getValue()),
      meta: {
        textAlign: 'center',
      },
    },

    {
      accessorKey: 'status',
      header: () => 'Request Status',
      cell: (props) =>
        statusOptions.find(({ value }) => value === props.getValue())?.title ??
        '---',
    },

    {
      accessorKey: 'updated_at',
      header: () => 'Updated At',
      cell: (props) => formatDateOnly(props?.getValue()),
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'action',
      header: () => 'Action',

      cell: (props) => {
        const element = props?.row?.original;
        const dropDownOptions = [
          { title: 'Preview', value: 'preview' },
          { title: 'In Process', value: 'Process' },
          { title: 'Completed', value: 'Approved' },
          { title: 'Cancelled', value: 'Rejected' },
        ];
        return (
          <div className="gap--xs w--full d--flex align-items--center justify-content--center">
            <Dropdown
              closeOnClickOutside={true}
              showcaret={false}
              dropList={{
                component: ({ item }) => (
                  <div
                    className="w--full d--flex  justify-content--start  p--sm"
                    onClick={() => {
                      if (item?.value === 'delete') {
                        deleteUserList(element);
                      } else {
                        handleDropListClick(element, item?.value);
                      }
                    }}
                  >
                    {item?.title}
                  </div>
                ),
                data: dropDownOptions,
              }}
              extraClasses="w-min--150"
            >
              <div className="w-min--32 h-min--32 d--flex align-items--center justify-content--center radius--sm bg--black-100 text--black  c--pointer">
                <MoreVIcon width={15} height={15} />
              </div>
            </Dropdown>
          </div>
        );
      },
      meta: {
        colType: 'action',
        textAlign: 'center',
        width: '5%',
      },
    },
  ];
  return (
    <>
      <div className="px-3">
        <TableManager
          {...{
            name: 'Porting Requests',
            fetcherKey: 'portingList',
            fetcherFn: portingList,
            columns,
            initialValues: filterInitialValues,
            shouldShowTotalCount: true,
          }}
        />
        {previewModal?.show ? (
          <AsideModal
            handleClose={() => {
              setPreviewModal({ show: false, data: null });
            }}
            title={'Preview porting'}
            footerComponent={null}
            width="900"
          >
            <PreviewPortRequest data={previewModal?.data} />
          </AsideModal>
        ) : null}
      </div>
    </>
  );
};

export default PortRequests;
