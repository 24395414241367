export const initialValues = {
  name: '',
  type: 'Buy',
  service: 'CALL',
  csv_file: '',
};
export const serviceTypes = [
  { label: 'Call', value: 'CALL' },
  { label: 'Sms', value: 'SMS' },
];
export const rateType = [
  { label: 'Buy', value: 'Buy' },
  { label: 'Sell', value: 'Sell' },
];
